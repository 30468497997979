@import "variables";
@import "normalize";
@import "bourbon/bourbon";
@import "neat/neat";

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html { background-color: $pink; }
html, body { min-height: 100%; }
body { font: normal 20px/1.5 $mercurydisplay; -webkit-font-smoothing:antialiased; background-color: $pink; 
  @include media($laptop) {
    font-size: 18px;
  }
  @include media($tablet) {
    font-size: 18px;
  }
}
.notice {
  max-width: 50em;
  margin: 0 auto; 
  h1 { text-align: center; font-size: 110px; padding: 20px 0; }
}
h1, .contact h3 { 
  font: bold 92px/0.9 $mercurydisplay; letter-spacing: -3px; margin: 10px 0 30px 0; 
  @include media($laptop) {
    font-size: 80px;
  }
  @include media($tablet) {
    font-size: 87px;
  }
  @include media($mobile) {
    font-size: 52px; letter-spacing: -2px; text-align: center; margin: 10px 0 0;
  }
}
h2 { font: bold italic 44px/1.1 $mercurydisplay; margin: 0; padding: 60px 0 30px 0; text-align: left; 
  @include media($laptop) {
    font-size: 38px;
  }
  @include media($tablet) {
    font-size: 32px;
    padding: 60px 0 10px 0; 
  }
  @include media($mobile) {
    font-size: 28px;
    padding: 40px 0 10px 0;
  }
}
h3 { font: bold 20px/24px $whitney; color: $green; text-transform: uppercase; margin: 60px 0 12px 0; 
  @include media($mobile) {
    font-size: 16px; text-align: center; margin: 30px 0 0; 
  }
  @include media($mini) {
    font-size: 14px;
  }
}
h4 { font: bold 20px/24px $whitney; margin-top: 50px; }
img { max-width: 100%; height: auto; display: block; }
a { color: $green; text-decoration: underline; font-weight: bold; 
  &:hover {
    color: shade($green,20%);
    text-decoration: none;
  }
}
.verticals {
  @include outer-container();
  padding: 0 0 0 80px;
  > div {
    @include span-columns(5.3333);
    p { font: 18px/1.5 $whitney; }
    text-align: left;
    padding: 80px 5% 40px 4%; 
    margin-bottom: 100px; 
    h3 { font-size: 25px; color: #999; 
      span { font: bold 50px/1 $mercurydisplay; text-transform: none; color: #000; display: block; }
    }
    position: relative; 
    &:after {
      @include linear-gradient(to top, $pinkle, $pink);
      content: ''; top: 0; left: 0; height: 100%; width: 1px; position: absolute;
    }
  }
}
a, a span { transition: all 0.1s; }
header { z-index: 99; position: relative; 
  @include media($mobile) {
    z-index: 999999;
  }
}
.notfound { text-align: center; 
  p { font-size: 40px; }
}
nav {
  .lightnav & { 
    a { color: #FFF; text-shadow: none; 
      &:hover { color: #FFF; }
    }
  }
  ul { overflow: hidden; padding-bottom: 30px; }
  a { padding: 40px 0 28px 0; font-weight: normal; font-family:$whitney; width: 18%; height: 120px; text-decoration: none; font-size: 22px; color: #000; display: inline-block; vertical-align: top; text-align: center; text-shadow: rgba(255,255,255,0.7) 1px 1px 3px; position: relative; 
    &:hover { color: #000; padding: 42px 0 6px 0; }
    &.active {
      color: $blue; 
      &:before { 
        content: ''; height: 0; width: 0; position: absolute; left: 50%; margin-left: -10px; top: 0; z-index: 99; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid $blue; }
    }
  }
  &.container { text-align: center; }
  .logo { padding: 34px 0 0 0; height: 88px; width: 18%; display: inline-block; 
    img, svg { width: 90px; height: auto; display: block; margin: 0 auto;  }
    a { display: block; margin: 0; padding: 0; background: transparent; width: 100%; }
    &:hover { color: #000; padding: 36px 0 6px 0; }
  }
  @include media($mobile) {
    position: fixed;
    bottom: 0; 
    left: 0;
    width: 100%;
    z-index: 99999999;
    box-shadow: rgba(0,0,0,0.4) 0 0 20px;
    background:$pinkle;
    padding: 0 20px;
    a { font-size: 13px; font-family: $whitney; height: auto; width: 19%; padding: 18px 0 0 0; font-weight: bold; text-align: left; color: rgba(255,255,255,0.7); text-shadow: none; float: left;
      &:hover { padding: 18px 0 0 0; color: #FFF; }
      &.active { color: #FFF; ;
        &:before { display: none; }
      }
      &.about-nav, &.blog-nav { text-align: right; padding: 18px 0 0 0; 
        &:hover { padding: 18px 0 0 0; }
      }
    }
    ul { padding-bottom: 0; }
    .logo { 
      padding: 15px 0 0 0; height: 60px; 
      width: 24%;
      img, svg { width: 60px; }
      &:hover { padding: 15px 0 0 0; }
    }
  }    
  @include media($mini) {
    a { padding: 14px 15px 0 0; font-size: 12px;
      &.about-nav, &.interact-nav { padding: 14px 0 0 15px; }
    }
    .logo {
      padding: 10px 0 0 0;
      height: 50px;
    }
  }  
}

.heart, .timeline h4, .text { transition: all 0.4s ease; }
.left, .right { transition: all 1s ease; }
.stuck {
  position:fixed;
  top:0;
  @include span-columns(6);
  padding: 0 83px 60px 0;
  @include media($tablet) {
    @include span-columns(7);
  }
  @include media($mobile) {
    position: relative; top: auto; padding: 0; 
    @include span-columns(16);
  }
}
.sticky {
}
body.new {
  background-image: url(/_img/banyan-leaves.png); background-repeat: no-repeat; background-position: top -90px center; background-size: 1550px auto;
  .lead {
    text-align: center; 
    padding-top: 140px;
    h1 { font-size: 142px; letter-spacing: -5px; }
    h4 { font: bold 26px/1.2 $mercurydisplay; max-width: 300px; margin: 0 auto 80px; letter-spacing: -1px; }
    .why {
      height: 200px; margin: 0; 
      a { width: 180px; height: 180px; border-radius: 100%; background: #FFF; display: inline-block; text-decoration: none; margin-top: 100px; vertical-align: top; transition: all 0.6s ease; position: relative; 
        span { font: bold 20px/1 $whitney; display: block; margin: 25px 0 15px;  }
        svg { position: absolute; width: 70px; height: auto; left: 50%; margin-left: -35px; top: 40px; transition: all 0.6s ease; }
        em { display: inline-block; margin-top: 15px; padding: 4px 12px; border-radius: 9px; font-style: normal; font-size: 13px; opacity: 0.5; font-weight: bold; color: #FFF; }
        &.expert { background: tint($gold,30%); 
          span { color: shade($gold,30%); }
          svg { width: 50px; margin-left: -25px; top: 50px; }
          path, polygon { fill: shade($gold, 40%); }
          em { background: shade($gold,50%); }
        }
        &.stable { background: tint($green, 60%); margin-top: 120px; 
          span { color: shade($green,20%); margin-top: 45px; }
          svg { top: -30px; }
          polygon, rect { fill: shade($green,40%); }
          em { background: shade($green,50%); }
        }
        &.flexible { background: tint($blue, 50%); margin-top: 92px;
          span { color: shade($blue,20%); }
          path, circle { fill: shade($blue, 60%); transition: all 0.8s ease; }
          em { background: shade($blue,50%); }
        }
        &.efficient { background: tint($orange, 50%); margin-top: 140px;
          span { color: shade($orange,20%); margin-top: 28px; }
          svg { top: -50px; margin-left: -25px; }
          rect, circle, path, polygon { fill: shade($orange,60%); }
          em { background: shade($orange,30%); }
        }
        p { opacity: 0; font: 15px/1.2 $whitney; color: #000; width: 180px; margin: 0 auto; position: relative; z-index: 99; transition: all 0.6s ease;  }
        &:hover { width: 270px; height: 270px; margin-top: -10px; 
          p { opacity: 1; }
          &.expert {
            svg { width: 120px; margin-left: -60px; opacity: 0.1; top: 80px; }
          }
          &.flexible {
            svg { width: 174px; margin-left: -87px; opacity: 0.1; }
          }
        }
       }

    }
  }
  nav {
    a { display: none; 
      &.logo { display: inline-block; width: 320px; 
        img, svg { width: 320px; margin: 20px auto; transition: all 0.3s ease; }
        &:hover { padding: 34px 0 0 0; 
          img, svg { transform: scale(1.03); }
        }
      }
    }
  }
  @include media($mobile) {
    background-size: 800px auto; background-position: top -30px center; 
    .lead {
      padding: 140px 0 0 0;
      h1 { font-size: 90px; }
      h4 { font-size: 22px; max-width: 220px; margin-bottom: 60px; }
      .why {
        height: 700px; position: relative; 
        a { position: absolute; left: 50%; width: 230px; height: 230px; margin-top: 0 !important; 
          p { opacity: 1; width: 160px; hyphens: none; font-size: 14px; }
          &.expert {
            top: 0; margin-left: -185px;
            svg { width: 80px; margin-left: -40px; opacity: 0.2; top: 60px; }
          }
          &.flexible { top: 155px; margin-left: -45px;
            svg { width: 174px; margin-left: -87px; opacity: 0.1; }
          }
          &.stable { top: 310px; margin-left: -190px; float: right;
          }
          &.efficient { top: 465px; margin-left: -40px;
          }
          &:hover { width: 230px; height: 230px; padding: 0; margin-top: 0; }
        }
      }
    }
    nav { position: absolute; top: 0; bottom: auto; background: transparent; box-shadow: none;
      a { 
        &.logo { width: 160px; position: absolute; left: 50%; margin: 30px 0 0 -80px; height: auto;
          img, svg { width: 100%; margin: 0 auto; }
        }
      }
    }
  }
}
.mobile-only { 
  display: none;
  @include media($mobile) {
    display: block;
  }
}
section { 
  @include outer-container();
  padding: 0 60px;
  &.dark {
    background: $pinker;
  }
  &.darkish {
    background: shade($pinker,10%);
  }
  &.darker {
    background: $pinkle;
    color: #FFF;
  }
  &.padded { 
    padding-top: 10px; padding-bottom: 30px; 
  }
  @include media($mobile) {
    padding: 0 20px;
    p {
      hyphens: auto;
      -webkit-hyphens: auto;      
    }
  }
}
.image2, .image3 { display: none; }
.btn { 
  background: $green; font: bold 17px/1.2 $whitney; color: #FFF; padding: 10px 25px; border-radius: 10px; text-decoration: none; 
  &:hover {
    background: shade($green, 10%); color: #FFF;
  }
}
.lead {
  padding: 0;
  overflow: hidden;
  .mobile { display: none; }
  &.about {
    padding: 0 60px;
    h1 { padding: 30px 0 40px 0; 
      @include span-columns(12);
      @include shift(2);
    }
    .intro {
      @include span-columns(10.5); 
      @include shift(2.75);
      @include columns(2);
      @include column-gap(40px);
      vertical-align: top;
      p {
        font-size: 22px;
        text-indent: 30px;
        margin: 0; 
        &:first-child {
          text-indent: 0;
          span { text-transform: uppercase; font-size: 19px;}
          &:first-letter {
            float: left;
            font: bold 163px/130px $whitney;
            color: $green;
            display: block;
            margin: 0 6px 0 0;
          }          
        }
      }
    }
    h1 { text-align: center; }
    @include media($tablet) {
      padding: 0 140px;
      .intro, h1 {
        padding-bottom: 0; 
        @include columns(1);
        @include span-columns(16);
        @include shift(0);
      }
    }
    @include media($mobile) {
      padding: 0 20px;
      .intro, h1 {
        p { font-size: 19px; 
          &:first-child {
            &:first-letter {
              font-size: 146px;
              line-height: 107px;
            }
          }
        }
      }
      h1 { 
        font-size: 64px; padding-bottom: 10px; }
    }
  }
  &.process {
    article {
      @include span-columns(16);
      @include outer-container();
    }
    h1 {
      @include span-columns(16);
      padding: 30px 0 40px 0; 
      text-align:center;
      font-size: 128px;
      letter-spacing: -6px; 
      max-width: none;
      br { display: none; }
    }
    .intro {
      @include span-columns(7);
      @include shift(8);
      span { text-transform: uppercase; font-size: 19px; letter-spacing: 1px; }
      padding: 0 0 0 0; 
      max-width: none;
    }
    padding-bottom: 100px;
  }
  article { 
    @include span-columns(9);
    .intro, .roles, h1, h3 { padding-left: 60px; padding-right: 40px; max-width: 700px; }
    .roles {
      margin: 20px 0 100px 0; 
      font: 18px/22px $whitney;
      @include columns(2);
      li { padding: 6px 0; list-style: none; 
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside:avoid;
        -moz-page-break-inside:avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
      }
      @include media($mobile) {
        font: bold 16px/18px $whitney; 
        text-align: center;
        padding: 0 25px;
        margin-bottom: 50px;
        li { padding: 6px; }
      }
      @include media($mini) {
        padding: 0; 
      }
    }
    .intro { padding-top: 100px; 
      p {
        b, strong { text-transform: uppercase; font-size: 18px; letter-spacing: 1px; font-weight: normal; }
        &:first-child {
          font: 24px/36px $mercurydisplay;
          &:first-letter {
            float: left;
            font: bold 195px/135px $mercurydisplay;
            color: $green;
            display: block;
            margin: 0 9px 0 0;
          }
        }
      }
    }
  }
  aside {
    position: relative;
    @include span-columns(7);
    img { box-shadow: rgba(0,0,0,0.5) 0 0 24px; position: absolute; top: 26px;}
  }
  @include media($tablet) {
    .mobile { display: block; float: right; zoom: 50%; padding: 0 60px;border-bottom: 1px #999 solid; }
    aside { display: none; }
    article {
      @include span-columns(16);
      .intro { padding-top: 0; padding-right: 0; }
    }
    h1 { margin: 0 0 80px; }
  }
  @include media($mobile) {
    padding: 0 20px;
    .mobile { float: none; zoom: auto; padding: 0; border-bottom: 0; 
      img { width: 75%; margin: 0 auto;}
    }
    aside { display: none; }
    h1 { margin-bottom: 50px; }
    article {
      .intro, h1, h3 { padding-left: 0; padding-right: 0;}
      .intro { padding-top: 20px; background: #FFF; margin: 0 -20px; padding: 20px;
        p {
          b, strong { font-size: 17px; letter-spacing: 0; }
          &:first-child {
            font: 22px/32px $mercurydisplay; 
            &:first-letter {
              font: bold 125px/90px $mercurydisplay; 
            }
          }
        }
      }
    }
    &.process {
      padding-bottom: 40px; 
      article {
        h1 { margin: 0; padding: 340px 0 20px; font-size: 61px; letter-spacing: -3px;
          br { display: block; }
        }
        .intro { 
          background: transparent;
          p {
            font-size: 18px;line-height: 1.4;
            span { letter-spacing: 1px; font-size: 17px; }
            &:first-child {
              &:first-letter {
                font-size: 95px;
                line-height: 69px;
              }
            }
          }
          @include span-columns(16);
          @include shift(0);
        }
      }      
    }
  }
}
.white { 
  background: #FFF;
}

// CASE STUDIES
.infographic {
  aside { 
    @include span-columns(6);
    padding: 0 50px 0 0;
    img { margin: -20px 0; }
  }
  article { 
    @include span-columns(10);
    padding-right: 60px;
    padding-bottom: 60px;
  }
  @include media($tablet) {
    aside { 
      @include span-columns(8);
      float: right; padding: 0 0 32px 40px; margin-right: 0;
    }
    article {
      @include span-columns(16);
      float: none;
      padding: 0 0 60px 0; 
    }
  }
  @include media($mobile) {
    aside {
      @include span-columns(12);
      @include shift(2);
      padding: 0; float: none;
    }
    article {
      @include span-columns(16);
      padding-right: 0;
      padding-bottom: 40px;
    }
  }
}
.results { 
  background: #FFF;
  position: relative;
  article {
    @include span-columns(7);
    @include shift(1);
    @include media($tablet) {
      @include span-columns(9);
      @include shift(0);
      padding-right: 20px;
    }
    @include media($mobile) {
      @include span-columns(16);
      @include shift(0);
      padding-right: 0;
      h4 { text-align: center; margin-bottom: 60px; }
    }
  }
  aside {
    @include span-columns(6);
    @include shift(1);
    position: relative;
    height: 600px;
    img { 
      position: absolute; 
      bottom: 0; 
      left: 0;
    }
    @include media($tablet) {
      @include span-columns(7);
      @include shift(0);
      height: 600px;
    }
    @include media($mobile) {
      @include span-columns(16);
      img { width: 80%; position: relative; margin: 0 auto; bottom: auto; left: auto;}
      height: auto;
    }
  }
  .btn { color: $magenta; border: 3px $magenta solid; font-size: 18px; padding: 10px 15px; background: #FFF; }
}
.screenshots {
  overflow: hidden;
  article {
    @include span-columns(6);
    padding: 0 40px 60px 0;
    p {
      hyphens: auto;
      -webkit-hyphens: auto;      
    }
  }
  aside {
    @include span-columns(10);
    position: relative;
    img { position: absolute; top: 150px; box-shadow: rgba(0,0,0,0.5) 0 0 24px; }
    &.solo {
      img { position: relative; top: 0; }
    }
  }
  .tabs {
    font: bold 20px/24px $whitney;
    margin: 60px 0 0 0;
    @include outer-container();
    a {
      color: #FFF; 
      display: block;
      float: left;
      position: relative;
      text-decoration: none;
      background: rgba(0,0,0,0.3);
      padding: 15px 20px;
      text-align: center;
      @include span-columns(5.3333333);
      &:hover {
        background: rgba(0,0,0,0.45);
        &.active:after {
            border-color:rgba(0, 0, 0, .6) transparent transparent;
        }
      }
      &.active {
        background:rgba(0,0,0,0.6);
      }
      &.active:after {
        width: 0;
        height: 0;
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -16px;
        bottom: -16px;
        border-style: solid;
        border-width: 16px 16px 0;
        border-color: rgba(0, 0, 0, .6) transparent transparent      }
    }
  }
  @include media($tablet) {
    article {
      @include span-columns(7);
    }
    aside {
      @include span-columns(9);
    }
    .tabs { 
      font: bold 16px/18px $whitney; 
    }
    .tab-content { 
      img { width: 135%; max-width: 135%; }
    }
  }
  @include media($mobile) {
    aside {
      @include span-columns(16);
      display: none;
      &.solo {
        display: block; 
      }      
    }
    article {
      @include span-columns(16);
      padding-right: 0; 
      padding-bottom: 20px;     
    }
    .tab-content { 
      img { width: 100%; max-width: 100%; }
    }    
  }
}
.responsive {
  padding-bottom: 80px;
  overflow: hidden;
  article {
    @include span-columns(11);
    img { float: left; zoom: 50%; margin: 0 60px 20px -300px; }
    @include media($mobile) {
      @include span-columns(16);
      img { zoom: 48%; margin-right: -100px; margin-left: 20px; float: right; }
    }
  }
  aside {
    @include span-columns(4);
    img { margin: 60px 60px 20px 0; }
    @include media($mobile) {
      @include span-columns(16);
      img { width: 75%; margin: 60px auto 0 auto; }
    }
  }
  @include media($mobile) {
    padding-bottom: 40px; 
  }
}

// ABOUT
.values {
  .heart { 
    position: fixed; left: 50%; top: 50%; margin: 0 0 0 -15px; z-index: 99; opacity: 0; 
    &.fixed { position: fixed; opacity: 1; }
    svg { width: 30px; height: 30px; animation: heartbeat 1s linear infinite; }
    path { fill: darken(red,10%); } 
    &.beat { animation: heartbeat 1s linear infinite; }
  }
  color: #888;
  .red { color: #000; }
  position: relative;
  padding-bottom: 220px;
  .line { left: 50%; border-right: 1px #CCC solid; height: 100%; position: absolute; }
  h3 { text-align: center; margin: 0; color: #000; font-size: 22px; letter-spacing: 4px; border-bottom: 1px #CCC solid; padding: 20px; }
  h2 { font: normal bold 48px/1 $mercurydisplay; padding: 0; }
  p { font: normal 20px/1.4 $whitney;}
  .left, .right { 
    @include span-columns(6);
    @include shift(1);
    margin-top: 100px;
    margin-bottom: -150px; 
  }
  .left {
    text-align: right; 
    h2 { text-align: right; }
  }
  .right {
    @include shift(9);
  }
  @include media($tablet) {
    .left, .right { 
      @include span-columns(7);
      @include shift(0);
    }
    .right {
      @include shift(9);
    }
    p { font-size: 18px; }
  }
  @include media($mobile) {
    padding: 0 0 50px 0;
    .left, .right {
      @include span-columns(14);
      @include shift(1);
      margin-top: 30px; margin-bottom: 0; 
      text-align: left;
      h2 { text-align: center; font-size: 48px; }
    }
    p { font-size: 18px; }
    .line { display: none; }
    .heart { top: 20px; }
  }
}
.people {
  margin-bottom: 100px;
  .image {
    @include span-columns(5);
    @include shift(1);
    opacity: 0.3;
    padding-top: 60px;
  }
  .text { 
    @include span-columns(8);
    @include shift(1);
    li { float: left; width: 50%; }
  }
  @include media($tablet) {
    .image {
      @include span-columns(7);
      @include shift(4.5);
    }
    .text {
      padding-top: 40px;
      @include span-columns(14);
      @include shift(1);
    }

  }
  @include media($mobile) {
    .image {
      @include span-columns(10);
      @include shift(3);
      padding-top: 0;
    }
    .text {
      padding-top: 40px;
      @include span-columns(14);
      @include shift(1);
    }
  }
}
.timeline {
  margin: 180px 0;
  position: relative; 
  .line { width: 100%; border-bottom: 1px #CCC solid; position: absolute; top: 40px; left: 0; 
  }
  @include media($tablet) {
    .line { display: none; }
    margin: 150px 0 150px 0; 
  }
  @include media($mobile) {
    margin-bottom: 50px; 
  }
  article { 
    @include outer-container();
    a { 
      width: 7%;
      float: left;
      text-align: center;
      display: block; 
      text-decoration: none; 
      position: relative; 
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      .linker { display: block; position: absolute; border-left: 3px #FFF solid; height: 50px; bottom: 60px; left: 38px; }
      &.tempdeactive,
      &.deactive { 
        h4 { color: #FFF; transform: scale(0.5); }
        .text { opacity: 0; bottom: 90px; }
        &.bottom {
          .text {
            bottom: auto; top: 90px; 
          }          
        }
        .linker { opacity: 0; }
      }
      &.off.deactive {
        h4 { transform: scale(1); color: $green; }
        &.bottom .text { top: 100px; bottom: auto; }
        .text { opacity: 1; bottom: 100px;  }
        .linker { opacity: 1; }
      }
      .notable { display: none; }
      h4 { width: 80px; height: 80px; margin: 0; line-height: 80px; background: #FFF; border-radius: 100%; 
        @include media($tablet) {
          transform: scale(0.8);
        }
      }
      .text { position: absolute; left: 0; bottom: 100px; background: #FFF; width: 250px; font: normal normal 16px/20px $whitney; text-align: left; padding: 0 15px; color: #000; border-radius: 9px;
      }
      &.bottom {
        .text { bottom: auto; top: 100px; }
        .linker { top: 50px; bottom: auto; }
      }
      &.item2015, &.item2016 {
        .text { left: -190px; width: 270px; }
      }
      @include media($tablet) {
        &.deactive h4 { transform: scale(0.4); border-color: #FFF; }
        &.item2014 {
          .text { left: -190px; width: 270px; }
        }
      }
      @include media($mobile) {
        width: 25%;
        display: inline-block;
        position: static;
        h4 { display: inline-block; transform: scale(1);}
        .linker { display: none; opacity: 0; }
        &.bottom {
          .text { bottom: auto; top: auto; }
        }
        &.item2015, &.item2016 {
          .text { width: 280px; right: 0; }
        }
        &.off.deactive {
          h4 { transform: scale(1); color: $green; }
          &.bottom .text { top: 100px; bottom: auto; }
          .text { opacity: 1; bottom: 100px;  }
          .linker { opacity: 1; }
        }

        .text { position: absolute; bottom: 270px !important; top: auto !important; left: 50% !important; right: 0 !important; width: 280px; margin-left: -140px; text-align: center; font-size: 16px; 
          p { hyphens: none; -webkit-hyphens: none; }
        }
      }
    }
  }
}

// PROCESS
.fuschia {
  background-image: url(/_img/fuschia-1x.png); background-repeat: no-repeat; background-size: 856px auto; background-position: right 110px top; width: 50%; height: 900px; position: absolute; top: 0; left: 0; 
  &.secondposition svg.humming { right: 79px; top: 565px; }
  &.thirdposition svg.humming { right: 386px; top: 755px; }
  &.bye svg.humming { right: 200px; top: -150px; transition: all 0.8s; }

  @include media($retina) {
    background-image: url(/_img/fuschia-2x.png); 
  }
  @include media($mobile) {
    background-image: url(/_img/fuschia-mobile.png); 
    background-size: 320px auto;
    background-position: left 0 top 0;
    width: 100%;
    height: 400px;  
    &.secondposition svg.humming { right: auto; left: 288px; top: 233px; }
    &.thirdposition svg.humming { right: auto; left: 288px; top: 233px; }    
    overflow: hidden;  
  }
  @include media($mini) {
    background-position: left -40px top 0;
    &.secondposition svg.humming { left: 248px; }
    &.thirdposition svg.humming { left: 248px; }    
  }
}
.wing {
  animation: flutter 0.15s ease-out; animation-iteration-count: infinite;  
}
.wings {
  animation: unflutter 0.15s ease-out; animation-iteration-count: infinite;  
}
.humming { position: absolute; width: 130px; height: auto; animation: shake 0.4s ease-out; animation-iteration-count: infinite; right: 68px; top: 490px; transition: all 0.1s ease;
  &:hover {
    .wing { display: none; }
    .wings { animation: none; }
  }
  &:active {
    .wing { display: none; }
    .wings { animation: none; }
    animation: none;     
  }
  @include media($mobile) {
    left: 228px;
    right: auto;
    top: 105px;
  }
  @include media($mini) {
    left: 188px;
  }
}
.tools {
  position: relative; 
  margin-top: 40px;
  margin-bottom: 80px;
  a { font-weight: inherit; color: #000; text-decoration: none; }
  h2 { font-style: normal; text-align: center; font-size: 64px; letter-spacing: -3px; line-height: 0.9; }
  .group { width: 300px; height: 300px; background: #FFF; border-radius: 100%; text-align: center; position: absolute; top: 0; left: 0; 
    &.back {
      left: 9%; top: 40px; width: 220px; height: 220px; 
      h4 { padding-top: 50px; }
    }
    &.front {
      left: 2%; top: 220px; width: 200px; height: 200px; 
    }
    &.create {
      left: 6%; top: 400px; width: 240px; height: 240px; 
      h4 { padding-top: 50px; }
    }
    &.host {
      right: 12%; left: auto; top: 60px; width: 180px; height: 180px; 
    }
    &.optim { 
      right: 3%; left: auto; top: 210px; width: 240px; height: 240px; 
      h4 { padding-top: 50px; }
    }
    &.pm { 
      h4 { padding: 40px 40px 0; }
      right: 10%; left: auto; top: 425px; width: 220px; height: 220px; 
    }
  }
  ul { font: normal 16px/1.1 $whitney; list-style: none; margin: 0; padding: 10px 40px; }
  li { margin: 5px 0; }
  h4 { text-transform: uppercase; font-size: 13px; line-height: 1; margin: 0; padding-top: 40px;  }
  @include media($mobile) {
    width: 100%; overflow: hidden; text-align: center; margin-bottom: 0;
    h2 { font-size: 36px; letter-spacing: -2px; padding: 10px 0; }
    .circles { width: 280px; height: 800px; overflow: visible; margin: 0 auto; position: relative; }
    .group { display: block; position: absolute; top: auto; bottom: auto !important; right: auto; left: auto; background: rgba(255,255,255,0.5); 
      &.front {
        left: -50px;
        top: 40px;
      }
      &.back {
        left: auto;
        right: -50px;
        top: 90px;
      }
      &.create {
        top: 245px;
        left: -40px;
      }
      &.host {
        top: 350px;
        left: auto;
        right: -60px;
      }
      &.optim {
        top: 455px;
        left: -70px;
      }
      &.pm {
        top: 550px;
        left: auto;
        right: -60px;
      }
    }
  }
}
.sprints, .support {
  text-align: center; 
  padding-bottom: 100px; 
  h2 { font: normal bold 28px/36px $whitney; margin: 0; text-align: center;  text-transform: uppercase; }
  p { font: normal normal 20px/28px $whitney; margin: 0 auto; max-width: 660px; }
  @include media($mobile) {
    padding-bottom: 50px; 
    h2 { font-size: 22px; line-height: 28px; padding-bottom: 10px; }
    p { font-size: 16px; line-height: 22px; }
  }
}
.pricing {
  background: tint($pinkle,20%) url(/_img/mystical.jpg) no-repeat bottom center; background-size: cover; ;
  color: #FFF;
  h2 { 
    @include shift(1);
    font-style: normal; font-size: 54px; line-height: 0.95; letter-spacing: -2px; margin-bottom: 20px; }
  .text {
    @include span-columns(8);
    @include shift(1);
    @include columns(2);
    @include column-gap(40px);
    p { margin: 0; padding: 0; text-indent: 30px; line-height: 1.3; 
      &:first-child { text-indent: 0; }
      span { text-transform: uppercase; font-weight: bold; font-size: 18px;  }
    }
    padding-bottom: 80px; 
  }
  @include media($mobile) {
    .text {
      @include shift(0);
      @include columns(1);
      @include span-columns(16);
    }
    h2 { font-size: 42px; 
      @include shift(0);
      br { display: none; }
    }
  }
}
.services {
  .image {
    @include span-columns(4);
    @include shift(1);
    opacity: 0.5;
    margin-top: 50px; 
  }
  ul { 
    @include span-columns(10);
    @include shift(1);
    margin-left: 0;
    margin-top: 30px; 
    padding-left: 90px; 
    font: bold 24px/1 $whitney;
    list-style: none;
    li { float: left; width: 50%; margin: 8px 0; padding-top: 3px; background: url(/img/check.svg) no-repeat left center; padding-left: 50px; background-size: 30px 30px; }
  }
}
.legend {
  padding: 40px 0 40px 0;
  a {
    position: relative;
    font: bold 15px/80px $whitney;
    color: #FFF;
    padding: 0;
    text-shadow: none; 
    width: 80px;
    height: 80px; 
    display: inline-block;
    text-decoration: none; 
    border-radius: 100%; 
    margin: 5px;
    &.research, &.research .tooltip { background: #B03C96; }
    &.content, &.content .tooltip { background: #E7802C; }
    &.design, &.design .tooltip { background: #F9C327; color: #000; }
    &.code, &.code .tooltip { background: #3763AE; }
    &.testing, &.testing .tooltip { background: #6F972A; }
    &:hover { padding: 0; }
    &.research:hover:before {
      border-left: 3px #B03C96 solid;
    }
    &.content:hover:before {
      border-left: 3px #E7802C solid;
    }
    &.design:hover:before {
      border-left: 3px #F9C327 solid;
    }
    &.code:hover:before {
      border-left: 3px #3763AE solid;
    }
    &.testing:hover:before {
      border-left: 3px #6F972A solid;
    }
    .tooltip {
      position: absolute; opacity: 0; bottom: 90px; left: 50%; width: 200px; text-align: center; font-size: 14px; font-weight: normal; padding: 15px; border-radius: 9px; margin-left: -100px; line-height: 1.2; transition: all 0.3s ease; pointer-events: none; 
    }
    &:hover, &:active { 
      .tooltip { opacity: 1; bottom: 100px; }
      &:before {
        content:''; position: absolute; left: 50%; margin-left: -2px; top: -20px; height: 20px;
      }
    }
  }
  @include media($mobile) {
    a { position: static; 
      &.research:hover:before,
      &.content:hover:before,
      &.design:hover:before,
      &.code:hover:before,
      &.testing:hover:before {
        border-left: 0; display: none;
      }

      .tooltip {
        margin-left: 0; width: 100%; left: 0; top: auto; bottom: 200px;
      }
      &:hover, &:active {
        .tooltip {
          bottom: 210px;  
        }
      }
    }
    position: relative; 
    max-width: 300px;
    margin: 0 auto;
  }
}
.donut {
  path {
    &#seg_research {
      fill: $colorresearch;
      &:hover {
        fill: shade($colorresearch,10%);
      }
      &.off {
        fill: desaturate(darken($colorresearch,5%), 40%);
      }
    }
    &#seg_content {
      fill: $colorcontent;
      &:hover {
        fill: shade($colorcontent,10%);
      }
      &.off {
        fill: desaturate($colorcontent, 60%);
      }
    }
    &#seg_design {
      fill: $colordesign;
      &:hover {
        fill: shade($colordesign,10%);
      }
      &.off {
        fill: desaturate(lighten($colordesign,5%), 70%);
      }
    }
    &#seg_code {
      fill: $colorcode;
      &:hover {
        fill: shade($colorcode,10%);
      }
      &.off {
        fill: desaturate($colorcode, 40%);
      }
    }
    &#seg_testing {
      fill: $colortesting;
      &:hover {
        fill: shade($colortesting,10%);
      }
      &.off {
        fill: desaturate(lighten($colortesting,5%), 50%);
      }
    }
  }
  circle { fill: $pink; }
}
.days {
  > a {
    width: 20%;
    display: block;
    float: left;
    height: 200px;
    display: flex; align-items: center; justify-content: center;
    position: relative; 
    svg { width: 90%; height: 90%; }
    span { display: block; z-index: 10; position: absolute; color: #000; font: bold 20px/20px $whitney; top: 50%; left: 0; width: 100%; height: auto; margin-top: -10px; transition: all 0.3s ease; }
    div {
      height: 200px;
      z-index: 5;
      pointer-events: none;
      &.what { position: absolute; left: 0; top: 0; width: 100%; font: 14px/1.2 $whitney; color: #000; height: 200px; display: flex; align-items: center; justify-content: center;
        strong { opacity: 0; transform: scale(0.1); transition: all 0.3s ease; background: rgba(255,255,255,0.9); font-weight: normal; padding: 20px; border-radius: 9px; z-index: 99; }
      }
    }
    &:hover { 
      div.what strong { display: block; opacity: 1; transform: scale(1); }
      span { opacity: 0; }
    }
    @include media($mobile) {
      width: 50%;
      height: 160px;
      div {
        height: 160px; 
        &.what {
          height: 160px;
          strong { padding: 15px; }
        }
      }
    }
  }
}
.support {
  padding-bottom: 20px;
  article { 
    @include span-columns(14);
    @include shift(1);
  }
  .plans {
    @include outer-container();
    margin-top: 60px; 
    border-top: 1px #777 solid; 
    svg { height: 80px; margin-top: 40px; }
    path { fill: shade($magenta,80%); }
  }
  .supportplan {
    @include span-columns(5.33333);
    position: relative;
    padding-bottom: 80px; 
    &:before { content: ''; width: 1px; height: 100%; position: absolute; top: 0; left: 0; 
      @include linear-gradient(#999, $pink);
    }

    &:first-child:before { 
      display: none;
    }
    h3 { color: #000; text-transform: none; font: 32px/1.2 $mercurydisplay; padding-bottom: 30px; margin: 20px 0 25px 0; position: relative; 
      &:before { content: ''; left: 50%; position: absolute; width: 40px; bottom: 0;  border-bottom: 2px #999 solid; margin-left: -20px; }
    }
    ul { list-style: none; padding: 0; font-size: 18px; line-height: 1.2; }
    li { padding: 5px 0; }
  }
  @include media($mobile) {
    .plans {
      margin-top: 40px;
      svg { height: 50px; margin-top: 20px; }
    }
    .supportplan { 
      @include span-columns(16);
      padding-bottom: 20px;
      &:before { display: none; }
      ul { font-size: 14px; line-height: 1;
      }
      h3 { margin-top: 10px; padding-bottom: 0; 
        &:before { display: none; }
      }
    }
  }
}
// GENERAL
.above {
  position: relative;
  z-index: 9999;
}
html.purple, body.thanks { background: shade($magenta,80%); }
body.thanks { 
  .contact { 
    text-align: center; padding: 100px 50px; 
    p { font-size: 24px; line-height: 1.2; }
  }
}
.contact {
  background: shade($magenta,80%);
  color: #FFF; 
  z-index: 1000;
  position: relative;
  padding: 0 0 100px 0; 
  article, form {
    max-width: 600px; 
    padding: 0 20px;
    margin: 0 auto;
    font-size: 25px; 
    line-height: 1.3;
    text-align: center; 
  }
  &.full {
    .btn { margin: 0 5px; }
    article {
    }
  }
  form {
    max-width: 500px;
  }
  h3 { color: #FFF; text-transform: none; text-align: center; margin: 0; padding: 60px 0 0 0; }
  @include media($mobile) {
    article { font-size: 18px; }
    p {
      hyphens: none;
      -webkit-hyphens: none;      
    }    
  }
}
.proposals {
  background: #DDD;
  h4 { text-align: center; font: bold 11px/1.2 $whitney; text-transform: uppercase; }
  form {
    border: 1px #CCC solid;
    background: #FFF;
    width: 320px; 
    padding: 20px; 
    margin: 0 auto;
    box-shadow: #666 0 10px 40px;
    input[type="text"],
    input[type="password"] { font: bold 18px/1.2 $whitney; background: #EEE; padding: 6px; width: 100%; outline: none; border:1px #999 solid; }
    label { font: bold 11px/1.2 $whitney; text-transform: uppercase; margin: 0; padding: 0; }
  }
}

// BLOG
.colorhead { 
  position: relative; margin-top: -140px; background: $pink url(/img/blog.svg) no-repeat center bottom; background-size: auto 120%; padding: 240px 0 250px; 
  h4 { text-align: center; margin: 0 0 20px; padding: 0; color: #FFF; text-transform: uppercase; opacity: 0.3; }
  h1 { color: #FFF; text-align: center; padding: 0 200px; line-height: 1; }
  @include media($tablet) {
    h1 { padding: 0 40px; font-size: 72px; }
    h4 { font-size: 18px; margin: 0 0 10px; }
  }
  @include media($mobile) {
    h1 { font-size: 48px; padding: 0 20px; }
    h4 { font-size: 16px; }
    margin-top: -180px; 
  }
}
.post {
  aside { position: fixed; left: 0; top: 0; bottom: 0; width: 36%; background-color: #EFEAE9; background-blend-mode: multiply; background-repeat: no-repeat; background-size: cover; background-position: center right;
    @include media($mobile) {
      position: relative; width: auto; margin: 0;  height: 350px; background-size: auto 100%; background-position: center center; 
    }
  }
  article { width: 55%; margin-left: 38%; padding: 60px 0;
    h1 { font-size: 84px; line-height: 80px;  }
    h2 { font: bold 32px/1.3 $whitney; padding: 30px 0 0 0; margin: 0; }
    h4 { color: #999; margin: 30px 0; font-size: 13px; text-align: center; }
    article { width: 100%; margin-left: 0; padding: 0; }
    a { color: $blue; 
      &:hover { color: shade($blue, 20%); }
    }
    @include media($mobile) {
      width: 100%; margin-left: 0; padding: 0; 
      h1 { font-size: 54px; line-height: 1; margin-bottom: 40px; }
      h2 { font-size: 25px; }
    }
  } 
  .intro {
    font-size: 32px; line-height: 1.3; padding-bottom: 20px; border-bottom: 1px shade($pinker,10%) solid; 
    @include media($mobile) {
      font-size: 24px; 
    }
  }
  .text { position: relative; 
    &.bordertop { border-top: 1px shade($pinker,10%) solid; margin-top: 40px; padding-top: 10px; }
  }
  &.newsletter {
    .intro { margin-top: -200px; position: relative; z-index: 100; font-size: 26px; line-height: 1.4; 
      &::first-letter { 
        color: tint($blue,30%); float: left; margin: 0 10px 0 -20px; font: bold 180px/130px $mercurydisplay; 
        @include media($mobile) {
          font: bold 150px/115px $mercurydisplay; margin: 0 10px 0 0; 
        }
      }
    }
    article { margin-left: 20%; width: 60%; 
      article { padding-left: 70px; margin-left: 0; width: 100%;
        .number { background: $magenta; color: #FFF; position: absolute; top: 34px; left: 0; width: 40px; height: 40px; border-radius: 100%; text-align: center; font: bold 26px/40px $whitney; }
      }
    }
  }
  @include media($tablet) {
    &.newsletter {
      padding: 0 40px; 
      article { margin-left: 5%; width: 90%; 
        article { margin-left: 0; width: 100%; }
      }
    }
  }
  @include media($mobile) {
    &.newsletter {
      padding: 0 30px; 
      article { margin-left: 0; width: 100%; 
        article { padding-left: 50px;
          .number { left: -10px; }
          p { line-height: 1.4; }
        }
      }
      .intro { margin-top: -210px; font-size: 22px; }
    }
  }
}
.plate {
  padding: 0 60px 40px; 
  &.redesign {
    background: linear-gradient(180deg, $green, $magenta);
    background-size: 600% 600%;    
  }
  width: 100%; 
  p { font-size: 36px; line-height: 1.1; opacity: 0.6; max-width: 700px; letter-spacing: -1px; }
  animation: redesign 7s ease infinite; margin: 0; 
  .text { }
  h1 { margin: 0; font: bold 108px/1.3 $whitney; }
  h4 {
    margin: 0 0 20px 0; 
    span { font-weight: 500; font-size: 15px; margin-left: 10px; text-transform: uppercase; }
  }
  @keyframes redesign { 
      0%{background-position:0% 44%}
      50%{background-position:100% 57%}
      100%{background-position:0% 44%}
  }
}
.pagination {
  text-align: center; 
  padding: 60px 0; 
  border-top: 1px shade($pinker,10%) solid; 
  a { display: inline-block; border: 2px $green solid; font: bold 18px/1.2 $whitney; border-radius: 12px; padding: 10px 20px; text-decoration: none; margin: 0 20px; 
    &:hover { background: $green; color: #FFF; }
  }
}
.blog.landing {
  article {
    padding-left: 450px; 
    padding-right: 10%; 
    position: relative;
    z-index: 100;
    ul { list-style: none; margin: 0; }
    p { margin: 0 0 15px 0; font-size: 24px; 
      a.btn { text-shadow: none !important; display: inline-block; margin-top: 20px; }
    }
    h4 { font-weight: normal; text-transform: uppercase; margin: 0 0 5px 0; font-size: 14px; 
      span { padding-left: 15px; }
    }
    h3 a {
      background-image: linear-gradient(rgba($green, 0.25) 0%, $green 100%);
      background-repeat: repeat-x; 
      background-size: 1px 1px;
      background-position: 0 90%;
      text-decoration: none;
      text-shadow: 3px 0 $pink, 2px 0 $pink, 1px 0 $pink, -1px 0 $pink, -2px 0 $pink, -3px 0 $pink;
      &:hover {
        background-image: linear-gradient(rgba($pink, 0.25) 0%, $pink 100%);
      }
    }

    li { padding: 30px 0 30px 0; }
    h5 { margin: 0; }
    h3 { font: bold 58px/1 $mercurydisplay; margin: 0; text-transform: none; margin-bottom: 30px;  }
  }
  aside {
    width: 40%; position: fixed; top: 50%; margin-top: -320px;
  }
  &.blogentry {
    nav a { color: #FFF; text-shadow: rgba(0,0,0,0.2) 1px 1px 1px; }
  }
  img { width: 600px; max-width: 600px; height: auto; margin: 0 0 0 -200px; display: block; z-index: 99; position: relative; }
  .butterfly.on { }
  .butterfly { left: 150px; position: absolute; top: 150px; margin: 0 0 0 74px; }
  @include media($laptop) {
    article { padding-left: 400px; padding-right: 0; }
    aside { width: 35%; }
  }
  @include media($tablet) {
    img { width: 500px; margin: 0 0 0 -150px; }
    article { padding-left: 350px; }
    aside { margin-top: -250px; }
    .butterfly { top: 103px; margin: 0 0 0 40px; }
  }
  @include media($mobile) {
    aside { position: relative; width: 100%; margin-top: -100px; }
    article { padding: 0; 
      h3 { text-align: left; font-size: 40px; }
      ul { padding: 0; }
      p { font-size: 20px; }
    }
    img { width: 400px; margin: 0 0 0 -80px; padding: 20px 0; }
    .butterfly { top: 95px; width: 90px; margin: 0 0 0 40px; }
  }
}

// HOME
.blogabout {
  @include outer-container();
  background: $pinker;
  display: flex;
  align-items: center;
  position: relative;
  img { 
    flex: 0 0 550px; width: 550px;
    position: absolute;
    margin: 80px 40px 40px -100px; display: block; 
  }
  .butterfly { 
    position: absolute; 
    left: 340px; top: 205px; 
  }
  article {
    &.blogpanel {
      margin: 40px 0 40px 450px;
      width: 100%; 
      position: relative;
      .thanks { position: absolute; bottom: 20px; padding: 0 120px; font: normal 18px/1.4 $mercurydisplay; opacity: 0; display: block; pointer-events: none; transition: all 0.5s ease;
        p { margin-bottom: 30px; }
        &.on { display: block; bottom: 30px; opacity: 1;  pointer-events: auto;}
      }
      .loader { 
        pointer-events: none; 
        display: block;
        opacity: 0; 
        .ring { border-color: shade($magenta,20%); }
      }
      form { max-width: 580px; margin: 0 auto; 
        p { font-size: 17px; text-align: center; padding: 10px 20px; float: left; width: 100%; }
      }
      input[type="email"] { float: left; width: 63%; }
      input[type="submit"] { float: left; width: 35%; margin-top: 0; margin-left: 2%; }
      @include media($mobile) {
        input[type="email"],
        input[type="submit"] { font-size: 17px; padding: 6px 10px; }

      }
      h2 { font: bold 40px/1.2 $whitney; color: $pinkle; text-align: center; text-transform: uppercase; padding: 60px 0 0 0; margin: 0; }
      ul { 
        @include outer-container();
        margin: 0;
        padding: 0 0 60px 0; list-style: none; font: bold 42px/1 $mercurydisplay; }
      li { 
        width: 50%; 
        float: left;
        padding: 0;
        height: 220px; border-bottom: 1px #999 solid; border-right: 1px #999 solid;
        list-style: none; text-align: center; 
        &:nth-child(3), &:nth-child(4) { border-bottom: 0; }
        &:nth-child(2), &:nth-child(4) { border-right: 0; }
        a { text-decoration: none; padding: 25px 40px 60px; color: #000; position: relative; display: block; height: 100%; align-items: center;
          strong { width: 100%; color: shade($magenta,80%); }
          span, em { font: normal 17px/1.2 $mercurydisplay; padding: 20px 20px 14px; text-transform: uppercase;
            &.meta { display: block; font: normal 14px/1.2 $whitney; text-align: center; 
            }
          }
          &:hover { background: tint($pink,20%); }
          em { font: normal normal 14px/1.2 $whitney; }
        }
      }
    }
  }
  @include media($tablet) {
    img { flex: 0 0 450px; width: 450px; margin-left: -200px; }
    article {
      &.blogpanel {
        ul { font-size: 32px; padding-bottom: 20px; }
        margin: 40px 0 40px 250px;
        li { width: 100%; height: auto; border: 0; 
          a { padding: 25px 10px; 
            span { padding: 0 0 10px; }
          }
        }
      }
    }
  }
  @include media($mobile) {
    display: block; 
    padding-top: 30px;
    img { flex: 0 0 240px; width: 240px; margin: 0 auto; position: relative; }
    .butterfly { left: 50%; margin-left: 30px; top: 45px; width: 60px; }
    article {
      @include span-columns(16);
      &.blogpanel {
        margin: 40px 0 40px 0;
      }
    }
  }
}
.homeprocess {
  background: $pinkle; 
  color: #FFF;
  display: flex;
  align-items: center;
  padding: 60px 30px 60px 60px; 
  justify-content: center;
  h2 { font-style: normal; letter-spacing: -3px; font-size: 68px; padding: 0 0 10px; }
  p { font: 22px/1.4 "Whitney A", "Whitney B", sans-serif; margin: 30px 0 40px 0; max-width: 284px; }
  circle { fill: $pinkle; }
  .text { 
    flex: 0 0 380px; width: 380px; 
  }
  .btn:hover { box-shadow: rgba(255,255,255,0.3) 0 0 20px; }
  .details {
    position: absolute; pointer-events: none; 
    top: 78px; left: 103px; padding: 0; text-align: center;
    border-radius: 100%; 
  }
  .donut { 
    flex-grow: 1;
    max-width: 500px;
    position: relative; 
    height: 100%;
    svg { width: 100%; }
    path { transition: all 0.2s ease; }
    text { pointer-events: none; }
    path:hover { cursor: pointer; }
    .days>div { position: absolute; width: 100%; height: 100%; transition: all 0.2s ease; display: flex; align-items: center; justify-content: center;
      &.details { width: 299px; height: 299px; display: block; }
      &.off { opacity: 1; }
      >div { height: 500px; }
      span { opacity: 0; display: flex; z-index: 10; color: #000; font: normal 20px/1.3 $whitney; width: 100%; height: auto; position: absolute; transition: all 0.3s ease; text-align: center; color: #FFF; align-items: center; justify-content: center; width: 299px; height: 299px; border-radius: 100%; padding: 40px 40px; box-shadow: rgba(0,0,0,0.1) 0 0 30px;
        transform: scale(0.8);
        &.grow { transform: scale(1); opacity: 1; }
        em { display: block; font-style: normal; }
        &.research { background: $colorresearch; }
        &.content { background: $colorcontent; }
        &.design { background: $colordesign; color: #000; }
        &.code { background: $colorcode; }
        &.testing { background: $colortesting; }
      }
    }
  }
  @include media($mobile) {
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 30px;
    h2 { text-align: center; font-size: 48px; }
    p { margin: 0 0 30px 0; max-width: 100%; text-align: center; hyphens: none; }
    text { display: none; }
    .text { 
      width: 100%; 
      padding: 20px 0 30px;
      flex: 0 0 auto; width: auto; 
    }
    .donut {
      height: auto; 
      margin: 0 0 20px 0;
      svg { height: 160px;}
      .days>div.details { display: none; }
    }
  }
}
.homework {
  h2 { text-align: center; font-style: normal; letter-spacing: -3px; font-size: 68px; padding: 65px 0 15px; }
  background: $pinker;
  p { text-align: center; padding: 30px 0; 
    .btn { padding: 10px 30px; }
  }
  .cases { 
    li {
      @include omega(3n);
    }
  }
  .quote {
    margin-top: 20px; 
    margin-bottom: 40px; 
    @include span-columns(11);
    @include shift(2.5);
    p { font: bold 30px/1.2 $mercurydisplay; padding: 0;  }
    span { font: bold 18px/1 $whitney; text-transform: uppercase; display: block; text-align: center; padding: 20px 0 60px 0; color: $pinkle; }
  }
  @include media($mobile) {
    h2 { font-size: 48px; }
    .quote {
      @include shift(1);
      @include span-columns(14);
      p { hyphens: none; font-size: 24px; }
    }
  }
}
.cases {
  @include outer-container();
  padding: 0 0 0 0;
  margin: 0; 
  list-style: none;
  li { 
    @include span-columns(5.333333);
    @include media($tablet) {
      @include span-columns(8);
      @include omega-reset(3n);
      @include omega(2n);
    }
  }
  svg { position: absolute; height: auto; width: 60px; top: 30px; left: 50%; margin-left: -30px; }
  path { fill: #000; }

  a { padding: 100px 0 10px 0; display: block; height: 190px; margin: 24px 0 0 0; position: relative; background-position: center 25px; background-repeat: no-repeat; background-size: 50px auto; text-align: center; border-right: 1px #999 solid; border-bottom: 1px #999 solid; border-left: 1px #CCC solid; border-top: 1px #CCC solid; background-color: rgba(255,255,255,0.2); color: #000; text-decoration: none; 
    &:hover { margin: 27px -2px -3px 2px; background-color: rgba(255,255,255,0.5); }
    strong { display: block; font-size: 32px; font-weight: normal; font-family: $mercury-sc; letter-spacing: -0; }
    span { display: block; font-size: 14px; font-weight: bold; text-transform: uppercase; letter-spacing: 0; margin-left: 2px; }
  }
  .small-screen-network svg { width: 80px; margin-left: -40px;  }
  .cedar-grove svg { width: 30px; height: auto; margin-left: -15px; }
  .kellogg-insight svg { }
  .tenants-union svg { width: 60px; margin-left: -30px; }
  .shopseptember svg { width: 90px; margin-left: -45px; }
  .no-barriers svg { width: 70px; margin-left: -35px; }
  .windermere svg { width: 70px; margin-left: -35px; }
  @include media($mobile) {
    li {
      @include span-columns(16);
    }
    &.second3 { display: none; }
  }
}
.featured { 
  @include outer-container();
  .cases { 
    @include span-columns(5.333333);
    li { 
      @include span-columns(16);
    }
    &.featured {
      @include span-columns(10.66666);
      &.right { float: right; margin-left: 2.12766%; margin-right: 0; }
      li a { height: 404px; padding-top: 180px; color: #FFF; text-shadow: #000 1px 1px 0; line-height: 48px; 
        strong { font-size: 50px; }
        span { font-size: 20px; }
      }
      svg { top: 100px;}
      path { fill: #FFF; stroke: #000; }
    }
  }
  @include media($mobile) {
    .cases {
      @include span-columns(16);
      li, li.featured, &.featured { 
        @include span-columns(16);
      }
    }
  }
}

form { overflow: hidden; position: relative; transition: all 0.7s ease; }
div.loader, div.thanks { position: absolute; display: none; left: 50%; top: 500px; margin-left: -30px; }
div.thanks { left: 0; margin-left: 0; text-align: center; width: 100%; padding: 0 100px; font: 700 36px/1 $mercurydisplay; }
@include media($mobile) {
  .loader, .thanks { top: 350px; }
}

.ring { border: 6px solid #FFF; border-radius: 100%; height: 60px; width: 60px; -webkit-animation: pulsate 1.5s ease-out; -webkit-animation-iteration-count: infinite;  opacity: 0.0; }

input[type="submit"] { cursor: pointer; background: $green; color: #FFF; padding: 12px 30px; font: bold 24px/30px $whitney; margin-top: 15px;  border-radius: 7px; border: 0; }
textarea { width: 100%; border: 0; height: 110px; border-radius: 7px; font: normal 24px/30px $whitney; padding: 12px 15px; outline: none;}
input {
  &[type="email"], &[type="text"] { display: block; font: normal 24px/30px $whitney; padding: 12px 15px; border: 0; margin: 0 auto; border-radius: 9px; width: 100%; outline: none;
    &.error { background: $pinker; }
  }
}
input.important { display: none; }
label { display: block; text-align: left; font: bold 15px/24px $whitney; text-transform: uppercase;
  &.error { display: none; }
}

.butterfly {
    width: 126px;
    height: auto;
    transform: rotate(5deg)  translate(30px, 60px) scale(0.8, 0.8);
    transform-origin: center center;
    transition: all 3s ease-in;
    &.on {
      transform: rotate(-5deg)  translate(-830px, -760px) scale(1.2, 1.2);
    }
}

.rightwing, .leftwing {
    transform-origin: center left;
    animation-name: leftwingani;
    animation-duration: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    &.slow { 
      transform-origin: center left;
      animation-name: leftwingani;
      animation-duration: 0.4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      &.leftwing { transform-origin: center right; }
    }
    &.slower { 
      transform-origin: center left;
      animation-name: leftwingani;
      animation-duration: 0.6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      &.leftwing { transform-origin: center right; }
    }
    &.stop { 
      animation: none;
    }
}
.leftwing {    
    transform-origin: center right;
}

@keyframes leftwingani {
    from {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    20%  {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    70% {
        transform: rotate3d(0, 1, 0, 80deg);
    }
    90%{
        transform: rotate3d(0, 1, 0, 00deg);
    }
    to{
        transform: rotate3d(0, 1, 0, 00deg);
    }
}

@keyframes pulsate {
    0% {-webkit-transform: scale(0, 0); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.5, 1.5); opacity: 0.0;}
}
@keyframes heartbeat {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.18); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.15); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}
@keyframes flutter {
    0% { opacity: 0.0; }
    50% { opacity: 1.0; }
    100% { opacity: 0.0; }
}
@keyframes unflutter {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes shake {
  2% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  4% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  6% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  8% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  12% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  16% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  18% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  20% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  22% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  24% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  26% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  28% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  30% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  32% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  34% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  38% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  40% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  42% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  44% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  46% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  50% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  52% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  54% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  56% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  58% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  60% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  62% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  64% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  66% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  68% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  70% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  72% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  74% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  76% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  80% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  84% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  86% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  90% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  92% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  94% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  96% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  98% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } 
}
