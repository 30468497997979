/* Responsive Mixins
   see: http://jakearchibald.github.com/sass-ie
------------------------------------------------ */
.no-mobile {
  display: none; }
  @media screen and (min-width: max-width 750px) {
    .no-mobile {
      display: block; }
      .no-mobile.inline {
        display: inline; } }

.no-mobile-inline {
  display: none; }
  @media screen and (min-width: max-width 750px) {
    .no-mobile-inline {
      display: inline-block; } }

.mobile-only {
  display: block; }
  @media screen and (min-width: max-width 750px) {
    .mobile-only {
      display: none; } }

.tablet {
  display: block; }
  @media screen and (min-width: max-width 960px) {
    .tablet {
      display: none; } }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

html {
  background: #fff;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a {
  background: transparent; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

q:before,
q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  background-color: #EFEAE9; }

html, body {
  min-height: 100%; }

body {
  font: normal 20px/1.5 "Mercury Display A", "Mercury Display B", serif;
  -webkit-font-smoothing: antialiased;
  background-color: #EFEAE9; }
  @media screen and (max-width: 1100px) {
    body {
      font-size: 18px; } }
  @media screen and (max-width: 960px) {
    body {
      font-size: 18px; } }

.notice {
  max-width: 50em;
  margin: 0 auto; }
  .notice h1 {
    text-align: center;
    font-size: 110px;
    padding: 20px 0; }

h1, .contact h3 {
  font: bold 92px/0.9 "Mercury Display A", "Mercury Display B", serif;
  letter-spacing: -3px;
  margin: 10px 0 30px 0; }
  @media screen and (max-width: 1100px) {
    h1, .contact h3 {
      font-size: 80px; } }
  @media screen and (max-width: 960px) {
    h1, .contact h3 {
      font-size: 87px; } }
  @media screen and (max-width: 750px) {
    h1, .contact h3 {
      font-size: 52px;
      letter-spacing: -2px;
      text-align: center;
      margin: 10px 0 0; } }

h2 {
  font: bold italic 44px/1.1 "Mercury Display A", "Mercury Display B", serif;
  margin: 0;
  padding: 60px 0 30px 0;
  text-align: left; }
  @media screen and (max-width: 1100px) {
    h2 {
      font-size: 38px; } }
  @media screen and (max-width: 960px) {
    h2 {
      font-size: 32px;
      padding: 60px 0 10px 0; } }
  @media screen and (max-width: 750px) {
    h2 {
      font-size: 28px;
      padding: 40px 0 10px 0; } }

h3 {
  font: bold 20px/24px "Whitney A", "Whitney B", sans-serif;
  color: #6F972A;
  text-transform: uppercase;
  margin: 60px 0 12px 0; }
  @media screen and (max-width: 750px) {
    h3 {
      font-size: 16px;
      text-align: center;
      margin: 30px 0 0; } }
  @media screen and (max-width: 360px) {
    h3 {
      font-size: 14px; } }

h4 {
  font: bold 20px/24px "Whitney A", "Whitney B", sans-serif;
  margin-top: 50px; }

img {
  max-width: 100%;
  height: auto;
  display: block; }

a {
  color: #6F972A;
  text-decoration: underline;
  font-weight: bold; }
  a:hover {
    color: #597922;
    text-decoration: none; }

.verticals {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 0 80px; }
  .verticals::after {
    clear: both;
    content: "";
    display: table; }
  .verticals > div {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 31.91468%;
    text-align: left;
    padding: 80px 5% 40px 4%;
    margin-bottom: 100px;
    position: relative; }
    .verticals > div:last-child {
      margin-right: 0; }
    .verticals > div p {
      font: 18px/1.5 "Whitney A", "Whitney B", sans-serif; }
    .verticals > div h3 {
      font-size: 25px;
      color: #999; }
      .verticals > div h3 span {
        font: bold 50px/1 "Mercury Display A", "Mercury Display B", serif;
        text-transform: none;
        color: #000;
        display: block; }
    .verticals > div:after {
      background-color: #7F6C72;
      background-image: -webkit-linear-gradient(bottom, #7F6C72, #EFEAE9);
      background-image: linear-gradient(to top,#7F6C72, #EFEAE9);
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      position: absolute; }

a, a span {
  transition: all 0.1s; }

header {
  z-index: 99;
  position: relative; }
  @media screen and (max-width: 750px) {
    header {
      z-index: 999999; } }

.notfound {
  text-align: center; }
  .notfound p {
    font-size: 40px; }

.lightnav nav a {
  color: #FFF;
  text-shadow: none; }
  .lightnav nav a:hover {
    color: #FFF; }

nav ul {
  overflow: hidden;
  padding-bottom: 30px; }

nav a {
  padding: 40px 0 28px 0;
  font-weight: normal;
  font-family: "Whitney A", "Whitney B", sans-serif;
  width: 18%;
  height: 120px;
  text-decoration: none;
  font-size: 22px;
  color: #000;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.7) 1px 1px 3px;
  position: relative; }
  nav a:hover {
    color: #000;
    padding: 42px 0 6px 0; }
  nav a.active {
    color: #3A65AC; }
    nav a.active:before {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      top: 0;
      z-index: 99;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #3A65AC; }

nav.container {
  text-align: center; }

nav .logo {
  padding: 34px 0 0 0;
  height: 88px;
  width: 18%;
  display: inline-block; }
  nav .logo img, nav .logo svg {
    width: 90px;
    height: auto;
    display: block;
    margin: 0 auto; }
  nav .logo a {
    display: block;
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%; }
  nav .logo:hover {
    color: #000;
    padding: 36px 0 6px 0; }

@media screen and (max-width: 750px) {
  nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px;
    background: #7F6C72;
    padding: 0 20px; }
    nav a {
      font-size: 13px;
      font-family: "Whitney A", "Whitney B", sans-serif;
      height: auto;
      width: 19%;
      padding: 18px 0 0 0;
      font-weight: bold;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      text-shadow: none;
      float: left; }
      nav a:hover {
        padding: 18px 0 0 0;
        color: #FFF; }
      nav a.active {
        color: #FFF; }
        nav a.active:before {
          display: none; }
      nav a.about-nav, nav a.blog-nav {
        text-align: right;
        padding: 18px 0 0 0; }
        nav a.about-nav:hover, nav a.blog-nav:hover {
          padding: 18px 0 0 0; }
    nav ul {
      padding-bottom: 0; }
    nav .logo {
      padding: 15px 0 0 0;
      height: 60px;
      width: 24%; }
      nav .logo img, nav .logo svg {
        width: 60px; }
      nav .logo:hover {
        padding: 15px 0 0 0; } }

@media screen and (max-width: 360px) {
  nav a {
    padding: 14px 15px 0 0;
    font-size: 12px; }
    nav a.about-nav, nav a.interact-nav {
      padding: 14px 0 0 15px; }
  nav .logo {
    padding: 10px 0 0 0;
    height: 50px; } }

.heart, .timeline h4, .text {
  transition: all 0.4s ease; }

.left, .right {
  transition: all 1s ease; }

.stuck {
  position: fixed;
  top: 0;
  float: left;
  display: block;
  margin-right: 2.12766%;
  width: 36.17021%;
  padding: 0 83px 60px 0; }
  .stuck:last-child {
    margin-right: 0; }
  @media screen and (max-width: 960px) {
    .stuck {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 42.55319%; }
      .stuck:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 750px) {
    .stuck {
      position: relative;
      top: auto;
      padding: 0;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .stuck:last-child {
        margin-right: 0; } }

body.new {
  background-image: url(/_img/banyan-leaves.png);
  background-repeat: no-repeat;
  background-position: top -90px center;
  background-size: 1550px auto; }
  body.new .lead {
    text-align: center;
    padding-top: 140px; }
    body.new .lead h1 {
      font-size: 142px;
      letter-spacing: -5px; }
    body.new .lead h4 {
      font: bold 26px/1.2 "Mercury Display A", "Mercury Display B", serif;
      max-width: 300px;
      margin: 0 auto 80px;
      letter-spacing: -1px; }
    body.new .lead .why {
      height: 200px;
      margin: 0; }
      body.new .lead .why a {
        width: 180px;
        height: 180px;
        border-radius: 100%;
        background: #FFF;
        display: inline-block;
        text-decoration: none;
        margin-top: 100px;
        vertical-align: top;
        transition: all 0.6s ease;
        position: relative; }
        body.new .lead .why a span {
          font: bold 20px/1 "Whitney A", "Whitney B", sans-serif;
          display: block;
          margin: 25px 0 15px; }
        body.new .lead .why a svg {
          position: absolute;
          width: 70px;
          height: auto;
          left: 50%;
          margin-left: -35px;
          top: 40px;
          transition: all 0.6s ease; }
        body.new .lead .why a em {
          display: inline-block;
          margin-top: 15px;
          padding: 4px 12px;
          border-radius: 9px;
          font-style: normal;
          font-size: 13px;
          opacity: 0.5;
          font-weight: bold;
          color: #FFF; }
        body.new .lead .why a.expert {
          background: #f9d475; }
          body.new .lead .why a.expert span {
            color: #ad8729; }
          body.new .lead .why a.expert svg {
            width: 50px;
            margin-left: -25px;
            top: 50px; }
          body.new .lead .why a.expert path, body.new .lead .why a.expert polygon {
            fill: #947423; }
          body.new .lead .why a.expert em {
            background: #7c611d; }
        body.new .lead .why a.stable {
          background: #c5d5aa;
          margin-top: 120px; }
          body.new .lead .why a.stable span {
            color: #597922;
            margin-top: 45px; }
          body.new .lead .why a.stable svg {
            top: -30px; }
          body.new .lead .why a.stable polygon, body.new .lead .why a.stable rect {
            fill: #435b19; }
          body.new .lead .why a.stable em {
            background: #384c15; }
        body.new .lead .why a.flexible {
          background: #9db2d6;
          margin-top: 92px; }
          body.new .lead .why a.flexible span {
            color: #2e518a; }
          body.new .lead .why a.flexible path, body.new .lead .why a.flexible circle {
            fill: #172845;
            transition: all 0.8s ease; }
          body.new .lead .why a.flexible em {
            background: #1d3356; }
        body.new .lead .why a.efficient {
          background: #f2bf9b;
          margin-top: 140px; }
          body.new .lead .why a.efficient span {
            color: #b6662c;
            margin-top: 28px; }
          body.new .lead .why a.efficient svg {
            top: -50px;
            margin-left: -25px; }
          body.new .lead .why a.efficient rect, body.new .lead .why a.efficient circle, body.new .lead .why a.efficient path, body.new .lead .why a.efficient polygon {
            fill: #5b3316; }
          body.new .lead .why a.efficient em {
            background: #a05927; }
        body.new .lead .why a p {
          opacity: 0;
          font: 15px/1.2 "Whitney A", "Whitney B", sans-serif;
          color: #000;
          width: 180px;
          margin: 0 auto;
          position: relative;
          z-index: 99;
          transition: all 0.6s ease; }
        body.new .lead .why a:hover {
          width: 270px;
          height: 270px;
          margin-top: -10px; }
          body.new .lead .why a:hover p {
            opacity: 1; }
          body.new .lead .why a:hover.expert svg {
            width: 120px;
            margin-left: -60px;
            opacity: 0.1;
            top: 80px; }
          body.new .lead .why a:hover.flexible svg {
            width: 174px;
            margin-left: -87px;
            opacity: 0.1; }
  body.new nav a {
    display: none; }
    body.new nav a.logo {
      display: inline-block;
      width: 320px; }
      body.new nav a.logo img, body.new nav a.logo svg {
        width: 320px;
        margin: 20px auto;
        transition: all 0.3s ease; }
      body.new nav a.logo:hover {
        padding: 34px 0 0 0; }
        body.new nav a.logo:hover img, body.new nav a.logo:hover svg {
          transform: scale(1.03); }
  @media screen and (max-width: 750px) {
    body.new {
      background-size: 800px auto;
      background-position: top -30px center; }
      body.new .lead {
        padding: 140px 0 0 0; }
        body.new .lead h1 {
          font-size: 90px; }
        body.new .lead h4 {
          font-size: 22px;
          max-width: 220px;
          margin-bottom: 60px; }
        body.new .lead .why {
          height: 700px;
          position: relative; }
          body.new .lead .why a {
            position: absolute;
            left: 50%;
            width: 230px;
            height: 230px;
            margin-top: 0 !important; }
            body.new .lead .why a p {
              opacity: 1;
              width: 160px;
              hyphens: none;
              font-size: 14px; }
            body.new .lead .why a.expert {
              top: 0;
              margin-left: -185px; }
              body.new .lead .why a.expert svg {
                width: 80px;
                margin-left: -40px;
                opacity: 0.2;
                top: 60px; }
            body.new .lead .why a.flexible {
              top: 155px;
              margin-left: -45px; }
              body.new .lead .why a.flexible svg {
                width: 174px;
                margin-left: -87px;
                opacity: 0.1; }
            body.new .lead .why a.stable {
              top: 310px;
              margin-left: -190px;
              float: right; }
            body.new .lead .why a.efficient {
              top: 465px;
              margin-left: -40px; }
            body.new .lead .why a:hover {
              width: 230px;
              height: 230px;
              padding: 0;
              margin-top: 0; }
      body.new nav {
        position: absolute;
        top: 0;
        bottom: auto;
        background: transparent;
        box-shadow: none; }
        body.new nav a.logo {
          width: 160px;
          position: absolute;
          left: 50%;
          margin: 30px 0 0 -80px;
          height: auto; }
          body.new nav a.logo img, body.new nav a.logo svg {
            width: 100%;
            margin: 0 auto; } }

.mobile-only {
  display: none; }
  @media screen and (max-width: 750px) {
    .mobile-only {
      display: block; } }

section {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 60px; }
  section::after {
    clear: both;
    content: "";
    display: table; }
  section.dark {
    background: #E5DBDC; }
  section.darkish {
    background: #cec5c6; }
  section.darker {
    background: #7F6C72;
    color: #FFF; }
  section.padded {
    padding-top: 10px;
    padding-bottom: 30px; }
  @media screen and (max-width: 750px) {
    section {
      padding: 0 20px; }
      section p {
        hyphens: auto;
        -webkit-hyphens: auto; } }

.image2, .image3 {
  display: none; }

.btn {
  background: #6F972A;
  font: bold 17px/1.2 "Whitney A", "Whitney B", sans-serif;
  color: #FFF;
  padding: 10px 25px;
  border-radius: 10px;
  text-decoration: none; }
  .btn:hover {
    background: #648826;
    color: #FFF; }

.lead {
  padding: 0;
  overflow: hidden; }
  .lead .mobile {
    display: none; }
  .lead.about {
    padding: 0 60px; }
    .lead.about h1 {
      padding: 30px 0 40px 0;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 74.46809%;
      margin-left: 12.76596%; }
      .lead.about h1:last-child {
        margin-right: 0; }
    .lead.about .intro {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 64.89362%;
      margin-left: 17.55319%;
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      -webkit-column-gap: 40px;
      -moz-column-gap: 40px;
      column-gap: 40px;
      vertical-align: top; }
      .lead.about .intro:last-child {
        margin-right: 0; }
      .lead.about .intro p {
        font-size: 22px;
        text-indent: 30px;
        margin: 0; }
        .lead.about .intro p:first-child {
          text-indent: 0; }
          .lead.about .intro p:first-child span {
            text-transform: uppercase;
            font-size: 19px; }
          .lead.about .intro p:first-child:first-letter {
            float: left;
            font: bold 163px/130px "Whitney A", "Whitney B", sans-serif;
            color: #6F972A;
            display: block;
            margin: 0 6px 0 0; }
    .lead.about h1 {
      text-align: center; }
    @media screen and (max-width: 960px) {
      .lead.about {
        padding: 0 140px; }
        .lead.about .intro, .lead.about h1 {
          padding-bottom: 0;
          -webkit-columns: 1;
          -moz-columns: 1;
          columns: 1;
          float: left;
          display: block;
          margin-right: 2.12766%;
          width: 100%;
          margin-left: 0%; }
          .lead.about .intro:last-child, .lead.about h1:last-child {
            margin-right: 0; } }
    @media screen and (max-width: 750px) {
      .lead.about {
        padding: 0 20px; }
        .lead.about .intro p, .lead.about h1 p {
          font-size: 19px; }
          .lead.about .intro p:first-child:first-letter, .lead.about h1 p:first-child:first-letter {
            font-size: 146px;
            line-height: 107px; }
        .lead.about h1 {
          font-size: 64px;
          padding-bottom: 10px; } }
  .lead.process {
    padding-bottom: 100px; }
    .lead.process article {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%;
      max-width: 1900px;
      margin-left: auto;
      margin-right: auto; }
      .lead.process article:last-child {
        margin-right: 0; }
      .lead.process article::after {
        clear: both;
        content: "";
        display: table; }
    .lead.process h1 {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%;
      padding: 30px 0 40px 0;
      text-align: center;
      font-size: 128px;
      letter-spacing: -6px;
      max-width: none; }
      .lead.process h1:last-child {
        margin-right: 0; }
      .lead.process h1 br {
        display: none; }
    .lead.process .intro {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 42.55319%;
      margin-left: 51.06383%;
      padding: 0 0 0 0;
      max-width: none; }
      .lead.process .intro:last-child {
        margin-right: 0; }
      .lead.process .intro span {
        text-transform: uppercase;
        font-size: 19px;
        letter-spacing: 1px; }
  .lead article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 55.31915%; }
    .lead article:last-child {
      margin-right: 0; }
    .lead article .intro, .lead article .roles, .lead article h1, .lead article h3 {
      padding-left: 60px;
      padding-right: 40px;
      max-width: 700px; }
    .lead article .roles {
      margin: 20px 0 100px 0;
      font: 18px/22px "Whitney A", "Whitney B", sans-serif;
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2; }
      .lead article .roles li {
        padding: 6px 0;
        list-style: none;
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        -moz-page-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column; }
      @media screen and (max-width: 750px) {
        .lead article .roles {
          font: bold 16px/18px "Whitney A", "Whitney B", sans-serif;
          text-align: center;
          padding: 0 25px;
          margin-bottom: 50px; }
          .lead article .roles li {
            padding: 6px; } }
      @media screen and (max-width: 360px) {
        .lead article .roles {
          padding: 0; } }
    .lead article .intro {
      padding-top: 100px; }
      .lead article .intro p b, .lead article .intro p strong {
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: normal; }
      .lead article .intro p:first-child {
        font: 24px/36px "Mercury Display A", "Mercury Display B", serif; }
        .lead article .intro p:first-child:first-letter {
          float: left;
          font: bold 195px/135px "Mercury Display A", "Mercury Display B", serif;
          color: #6F972A;
          display: block;
          margin: 0 9px 0 0; }
  .lead aside {
    position: relative;
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 42.55319%; }
    .lead aside:last-child {
      margin-right: 0; }
    .lead aside img {
      box-shadow: rgba(0, 0, 0, 0.5) 0 0 24px;
      position: absolute;
      top: 26px; }
  @media screen and (max-width: 960px) {
    .lead .mobile {
      display: block;
      float: right;
      zoom: 50%;
      padding: 0 60px;
      border-bottom: 1px #999 solid; }
    .lead aside {
      display: none; }
    .lead article {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .lead article:last-child {
        margin-right: 0; }
      .lead article .intro {
        padding-top: 0;
        padding-right: 0; }
    .lead h1 {
      margin: 0 0 80px; } }
  @media screen and (max-width: 750px) {
    .lead {
      padding: 0 20px; }
      .lead .mobile {
        float: none;
        zoom: auto;
        padding: 0;
        border-bottom: 0; }
        .lead .mobile img {
          width: 75%;
          margin: 0 auto; }
      .lead aside {
        display: none; }
      .lead h1 {
        margin-bottom: 50px; }
      .lead article .intro, .lead article h1, .lead article h3 {
        padding-left: 0;
        padding-right: 0; }
      .lead article .intro {
        padding-top: 20px;
        background: #FFF;
        margin: 0 -20px;
        padding: 20px; }
        .lead article .intro p b, .lead article .intro p strong {
          font-size: 17px;
          letter-spacing: 0; }
        .lead article .intro p:first-child {
          font: 22px/32px "Mercury Display A", "Mercury Display B", serif; }
          .lead article .intro p:first-child:first-letter {
            font: bold 125px/90px "Mercury Display A", "Mercury Display B", serif; }
      .lead.process {
        padding-bottom: 40px; }
        .lead.process article h1 {
          margin: 0;
          padding: 340px 0 20px;
          font-size: 61px;
          letter-spacing: -3px; }
          .lead.process article h1 br {
            display: block; }
        .lead.process article .intro {
          background: transparent;
          float: left;
          display: block;
          margin-right: 2.12766%;
          width: 100%;
          margin-left: 0%; }
          .lead.process article .intro p {
            font-size: 18px;
            line-height: 1.4; }
            .lead.process article .intro p span {
              letter-spacing: 1px;
              font-size: 17px; }
            .lead.process article .intro p:first-child:first-letter {
              font-size: 95px;
              line-height: 69px; }
          .lead.process article .intro:last-child {
            margin-right: 0; } }

.white {
  background: #FFF; }

.infographic aside {
  float: left;
  display: block;
  margin-right: 2.12766%;
  width: 36.17021%;
  padding: 0 50px 0 0; }
  .infographic aside:last-child {
    margin-right: 0; }
  .infographic aside img {
    margin: -20px 0; }

.infographic article {
  float: left;
  display: block;
  margin-right: 2.12766%;
  width: 61.70213%;
  padding-right: 60px;
  padding-bottom: 60px; }
  .infographic article:last-child {
    margin-right: 0; }

@media screen and (max-width: 960px) {
  .infographic aside {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 48.93617%;
    float: right;
    padding: 0 0 32px 40px;
    margin-right: 0; }
    .infographic aside:last-child {
      margin-right: 0; }
  .infographic article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 100%;
    float: none;
    padding: 0 0 60px 0; }
    .infographic article:last-child {
      margin-right: 0; } }

@media screen and (max-width: 750px) {
  .infographic aside {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 74.46809%;
    margin-left: 12.76596%;
    padding: 0;
    float: none; }
    .infographic aside:last-child {
      margin-right: 0; }
  .infographic article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 100%;
    padding-right: 0;
    padding-bottom: 40px; }
    .infographic article:last-child {
      margin-right: 0; } }

.results {
  background: #FFF;
  position: relative; }
  .results article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 42.55319%;
    margin-left: 6.38298%; }
    .results article:last-child {
      margin-right: 0; }
    @media screen and (max-width: 960px) {
      .results article {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 55.31915%;
        margin-left: 0%;
        padding-right: 20px; }
        .results article:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 750px) {
      .results article {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%;
        margin-left: 0%;
        padding-right: 0; }
        .results article:last-child {
          margin-right: 0; }
        .results article h4 {
          text-align: center;
          margin-bottom: 60px; } }
  .results aside {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 36.17021%;
    margin-left: 6.38298%;
    position: relative;
    height: 600px; }
    .results aside:last-child {
      margin-right: 0; }
    .results aside img {
      position: absolute;
      bottom: 0;
      left: 0; }
    @media screen and (max-width: 960px) {
      .results aside {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 42.55319%;
        margin-left: 0%;
        height: 600px; }
        .results aside:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 750px) {
      .results aside {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%;
        height: auto; }
        .results aside:last-child {
          margin-right: 0; }
        .results aside img {
          width: 80%;
          position: relative;
          margin: 0 auto;
          bottom: auto;
          left: auto; } }
  .results .btn {
    color: #C4549F;
    border: 3px #C4549F solid;
    font-size: 18px;
    padding: 10px 15px;
    background: #FFF; }

.screenshots {
  overflow: hidden; }
  .screenshots article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 36.17021%;
    padding: 0 40px 60px 0; }
    .screenshots article:last-child {
      margin-right: 0; }
    .screenshots article p {
      hyphens: auto;
      -webkit-hyphens: auto; }
  .screenshots aside {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 61.70213%;
    position: relative; }
    .screenshots aside:last-child {
      margin-right: 0; }
    .screenshots aside img {
      position: absolute;
      top: 150px;
      box-shadow: rgba(0, 0, 0, 0.5) 0 0 24px; }
    .screenshots aside.solo img {
      position: relative;
      top: 0; }
  .screenshots .tabs {
    font: bold 20px/24px "Whitney A", "Whitney B", sans-serif;
    margin: 60px 0 0 0;
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto; }
    .screenshots .tabs::after {
      clear: both;
      content: "";
      display: table; }
    .screenshots .tabs a {
      color: #FFF;
      display: block;
      float: left;
      position: relative;
      text-decoration: none;
      background: rgba(0, 0, 0, 0.3);
      padding: 15px 20px;
      text-align: center;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 31.91489%; }
      .screenshots .tabs a:last-child {
        margin-right: 0; }
      .screenshots .tabs a:hover {
        background: rgba(0, 0, 0, 0.45); }
        .screenshots .tabs a:hover.active:after {
          border-color: rgba(0, 0, 0, 0.6) transparent transparent; }
      .screenshots .tabs a.active {
        background: rgba(0, 0, 0, 0.6); }
      .screenshots .tabs a.active:after {
        width: 0;
        height: 0;
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -16px;
        bottom: -16px;
        border-style: solid;
        border-width: 16px 16px 0;
        border-color: rgba(0, 0, 0, 0.6) transparent transparent; }
  @media screen and (max-width: 960px) {
    .screenshots article {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 42.55319%; }
      .screenshots article:last-child {
        margin-right: 0; }
    .screenshots aside {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 55.31915%; }
      .screenshots aside:last-child {
        margin-right: 0; }
    .screenshots .tabs {
      font: bold 16px/18px "Whitney A", "Whitney B", sans-serif; }
    .screenshots .tab-content img {
      width: 135%;
      max-width: 135%; } }
  @media screen and (max-width: 750px) {
    .screenshots aside {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%;
      display: none; }
      .screenshots aside:last-child {
        margin-right: 0; }
      .screenshots aside.solo {
        display: block; }
    .screenshots article {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px; }
      .screenshots article:last-child {
        margin-right: 0; }
    .screenshots .tab-content img {
      width: 100%;
      max-width: 100%; } }

.responsive {
  padding-bottom: 80px;
  overflow: hidden; }
  .responsive article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 68.08511%; }
    .responsive article:last-child {
      margin-right: 0; }
    .responsive article img {
      float: left;
      zoom: 50%;
      margin: 0 60px 20px -300px; }
    @media screen and (max-width: 750px) {
      .responsive article {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%; }
        .responsive article:last-child {
          margin-right: 0; }
        .responsive article img {
          zoom: 48%;
          margin-right: -100px;
          margin-left: 20px;
          float: right; } }
  .responsive aside {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 23.40426%; }
    .responsive aside:last-child {
      margin-right: 0; }
    .responsive aside img {
      margin: 60px 60px 20px 0; }
    @media screen and (max-width: 750px) {
      .responsive aside {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%; }
        .responsive aside:last-child {
          margin-right: 0; }
        .responsive aside img {
          width: 75%;
          margin: 60px auto 0 auto; } }
  @media screen and (max-width: 750px) {
    .responsive {
      padding-bottom: 40px; } }

.values {
  color: #888;
  position: relative;
  padding-bottom: 220px; }
  .values .heart {
    position: fixed;
    left: 50%;
    top: 50%;
    margin: 0 0 0 -15px;
    z-index: 99;
    opacity: 0; }
    .values .heart.fixed {
      position: fixed;
      opacity: 1; }
    .values .heart svg {
      width: 30px;
      height: 30px;
      animation: heartbeat 1s linear infinite; }
    .values .heart path {
      fill: #cc0000; }
    .values .heart.beat {
      animation: heartbeat 1s linear infinite; }
  .values .red {
    color: #000; }
  .values .line {
    left: 50%;
    border-right: 1px #CCC solid;
    height: 100%;
    position: absolute; }
  .values h3 {
    text-align: center;
    margin: 0;
    color: #000;
    font-size: 22px;
    letter-spacing: 4px;
    border-bottom: 1px #CCC solid;
    padding: 20px; }
  .values h2 {
    font: normal bold 48px/1 "Mercury Display A", "Mercury Display B", serif;
    padding: 0; }
  .values p {
    font: normal 20px/1.4 "Whitney A", "Whitney B", sans-serif; }
  .values .left, .values .right {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 36.17021%;
    margin-left: 6.38298%;
    margin-top: 100px;
    margin-bottom: -150px; }
    .values .left:last-child, .values .right:last-child {
      margin-right: 0; }
  .values .left {
    text-align: right; }
    .values .left h2 {
      text-align: right; }
  .values .right {
    margin-left: 57.44681%; }
  @media screen and (max-width: 960px) {
    .values .left, .values .right {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 42.55319%;
      margin-left: 0%; }
      .values .left:last-child, .values .right:last-child {
        margin-right: 0; }
    .values .right {
      margin-left: 57.44681%; }
    .values p {
      font-size: 18px; } }
  @media screen and (max-width: 750px) {
    .values {
      padding: 0 0 50px 0; }
      .values .left, .values .right {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 87.23404%;
        margin-left: 6.38298%;
        margin-top: 30px;
        margin-bottom: 0;
        text-align: left; }
        .values .left:last-child, .values .right:last-child {
          margin-right: 0; }
        .values .left h2, .values .right h2 {
          text-align: center;
          font-size: 48px; }
      .values p {
        font-size: 18px; }
      .values .line {
        display: none; }
      .values .heart {
        top: 20px; } }

.people {
  margin-bottom: 100px; }
  .people .image {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 29.78723%;
    margin-left: 6.38298%;
    opacity: 0.3;
    padding-top: 60px; }
    .people .image:last-child {
      margin-right: 0; }
  .people .text {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 48.93617%;
    margin-left: 6.38298%; }
    .people .text:last-child {
      margin-right: 0; }
    .people .text li {
      float: left;
      width: 50%; }
  @media screen and (max-width: 960px) {
    .people .image {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 42.55319%;
      margin-left: 28.7234%; }
      .people .image:last-child {
        margin-right: 0; }
    .people .text {
      padding-top: 40px;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 87.23404%;
      margin-left: 6.38298%; }
      .people .text:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 750px) {
    .people .image {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 61.70213%;
      margin-left: 19.14894%;
      padding-top: 0; }
      .people .image:last-child {
        margin-right: 0; }
    .people .text {
      padding-top: 40px;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 87.23404%;
      margin-left: 6.38298%; }
      .people .text:last-child {
        margin-right: 0; } }

.timeline {
  margin: 180px 0;
  position: relative; }
  .timeline .line {
    width: 100%;
    border-bottom: 1px #CCC solid;
    position: absolute;
    top: 40px;
    left: 0; }
  @media screen and (max-width: 960px) {
    .timeline {
      margin: 150px 0 150px 0; }
      .timeline .line {
        display: none; } }
  @media screen and (max-width: 750px) {
    .timeline {
      margin-bottom: 50px; } }
  .timeline article {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto; }
    .timeline article::after {
      clear: both;
      content: "";
      display: table; }
    .timeline article a {
      width: 7%;
      float: left;
      text-align: center;
      display: block;
      text-decoration: none;
      position: relative;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
      .timeline article a .linker {
        display: block;
        position: absolute;
        border-left: 3px #FFF solid;
        height: 50px;
        bottom: 60px;
        left: 38px; }
      .timeline article a.tempdeactive h4, .timeline article a.deactive h4 {
        color: #FFF;
        transform: scale(0.5); }
      .timeline article a.tempdeactive .text, .timeline article a.deactive .text {
        opacity: 0;
        bottom: 90px; }
      .timeline article a.tempdeactive.bottom .text, .timeline article a.deactive.bottom .text {
        bottom: auto;
        top: 90px; }
      .timeline article a.tempdeactive .linker, .timeline article a.deactive .linker {
        opacity: 0; }
      .timeline article a.off.deactive h4 {
        transform: scale(1);
        color: #6F972A; }
      .timeline article a.off.deactive.bottom .text {
        top: 100px;
        bottom: auto; }
      .timeline article a.off.deactive .text {
        opacity: 1;
        bottom: 100px; }
      .timeline article a.off.deactive .linker {
        opacity: 1; }
      .timeline article a .notable {
        display: none; }
      .timeline article a h4 {
        width: 80px;
        height: 80px;
        margin: 0;
        line-height: 80px;
        background: #FFF;
        border-radius: 100%; }
        @media screen and (max-width: 960px) {
          .timeline article a h4 {
            transform: scale(0.8); } }
      .timeline article a .text {
        position: absolute;
        left: 0;
        bottom: 100px;
        background: #FFF;
        width: 250px;
        font: normal normal 16px/20px "Whitney A", "Whitney B", sans-serif;
        text-align: left;
        padding: 0 15px;
        color: #000;
        border-radius: 9px; }
      .timeline article a.bottom .text {
        bottom: auto;
        top: 100px; }
      .timeline article a.bottom .linker {
        top: 50px;
        bottom: auto; }
      .timeline article a.item2015 .text, .timeline article a.item2016 .text {
        left: -190px;
        width: 270px; }
      @media screen and (max-width: 960px) {
        .timeline article a.deactive h4 {
          transform: scale(0.4);
          border-color: #FFF; }
        .timeline article a.item2014 .text {
          left: -190px;
          width: 270px; } }
      @media screen and (max-width: 750px) {
        .timeline article a {
          width: 25%;
          display: inline-block;
          position: static; }
          .timeline article a h4 {
            display: inline-block;
            transform: scale(1); }
          .timeline article a .linker {
            display: none;
            opacity: 0; }
          .timeline article a.bottom .text {
            bottom: auto;
            top: auto; }
          .timeline article a.item2015 .text, .timeline article a.item2016 .text {
            width: 280px;
            right: 0; }
          .timeline article a.off.deactive h4 {
            transform: scale(1);
            color: #6F972A; }
          .timeline article a.off.deactive.bottom .text {
            top: 100px;
            bottom: auto; }
          .timeline article a.off.deactive .text {
            opacity: 1;
            bottom: 100px; }
          .timeline article a.off.deactive .linker {
            opacity: 1; }
          .timeline article a .text {
            position: absolute;
            bottom: 270px !important;
            top: auto !important;
            left: 50% !important;
            right: 0 !important;
            width: 280px;
            margin-left: -140px;
            text-align: center;
            font-size: 16px; }
            .timeline article a .text p {
              hyphens: none;
              -webkit-hyphens: none; } }

.fuschia {
  background-image: url(/_img/fuschia-1x.png);
  background-repeat: no-repeat;
  background-size: 856px auto;
  background-position: right 110px top;
  width: 50%;
  height: 900px;
  position: absolute;
  top: 0;
  left: 0; }
  .fuschia.secondposition svg.humming {
    right: 79px;
    top: 565px; }
  .fuschia.thirdposition svg.humming {
    right: 386px;
    top: 755px; }
  .fuschia.bye svg.humming {
    right: 200px;
    top: -150px;
    transition: all 0.8s; }
  @media screen and (min-resolution: 1.5dppx) {
    .fuschia {
      background-image: url(/_img/fuschia-2x.png); } }
  @media screen and (max-width: 750px) {
    .fuschia {
      background-image: url(/_img/fuschia-mobile.png);
      background-size: 320px auto;
      background-position: left 0 top 0;
      width: 100%;
      height: 400px;
      overflow: hidden; }
      .fuschia.secondposition svg.humming {
        right: auto;
        left: 288px;
        top: 233px; }
      .fuschia.thirdposition svg.humming {
        right: auto;
        left: 288px;
        top: 233px; } }
  @media screen and (max-width: 360px) {
    .fuschia {
      background-position: left -40px top 0; }
      .fuschia.secondposition svg.humming {
        left: 248px; }
      .fuschia.thirdposition svg.humming {
        left: 248px; } }

.wing {
  animation: flutter 0.15s ease-out;
  animation-iteration-count: infinite; }

.wings {
  animation: unflutter 0.15s ease-out;
  animation-iteration-count: infinite; }

.humming {
  position: absolute;
  width: 130px;
  height: auto;
  animation: shake 0.4s ease-out;
  animation-iteration-count: infinite;
  right: 68px;
  top: 490px;
  transition: all 0.1s ease; }
  .humming:hover .wing {
    display: none; }
  .humming:hover .wings {
    animation: none; }
  .humming:active {
    animation: none; }
    .humming:active .wing {
      display: none; }
    .humming:active .wings {
      animation: none; }
  @media screen and (max-width: 750px) {
    .humming {
      left: 228px;
      right: auto;
      top: 105px; } }
  @media screen and (max-width: 360px) {
    .humming {
      left: 188px; } }

.tools {
  position: relative;
  margin-top: 40px;
  margin-bottom: 80px; }
  .tools a {
    font-weight: inherit;
    color: #000;
    text-decoration: none; }
  .tools h2 {
    font-style: normal;
    text-align: center;
    font-size: 64px;
    letter-spacing: -3px;
    line-height: 0.9; }
  .tools .group {
    width: 300px;
    height: 300px;
    background: #FFF;
    border-radius: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0; }
    .tools .group.back {
      left: 9%;
      top: 40px;
      width: 220px;
      height: 220px; }
      .tools .group.back h4 {
        padding-top: 50px; }
    .tools .group.front {
      left: 2%;
      top: 220px;
      width: 200px;
      height: 200px; }
    .tools .group.create {
      left: 6%;
      top: 400px;
      width: 240px;
      height: 240px; }
      .tools .group.create h4 {
        padding-top: 50px; }
    .tools .group.host {
      right: 12%;
      left: auto;
      top: 60px;
      width: 180px;
      height: 180px; }
    .tools .group.optim {
      right: 3%;
      left: auto;
      top: 210px;
      width: 240px;
      height: 240px; }
      .tools .group.optim h4 {
        padding-top: 50px; }
    .tools .group.pm {
      right: 10%;
      left: auto;
      top: 425px;
      width: 220px;
      height: 220px; }
      .tools .group.pm h4 {
        padding: 40px 40px 0; }
  .tools ul {
    font: normal 16px/1.1 "Whitney A", "Whitney B", sans-serif;
    list-style: none;
    margin: 0;
    padding: 10px 40px; }
  .tools li {
    margin: 5px 0; }
  .tools h4 {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1;
    margin: 0;
    padding-top: 40px; }
  @media screen and (max-width: 750px) {
    .tools {
      width: 100%;
      overflow: hidden;
      text-align: center;
      margin-bottom: 0; }
      .tools h2 {
        font-size: 36px;
        letter-spacing: -2px;
        padding: 10px 0; }
      .tools .circles {
        width: 280px;
        height: 800px;
        overflow: visible;
        margin: 0 auto;
        position: relative; }
      .tools .group {
        display: block;
        position: absolute;
        top: auto;
        bottom: auto !important;
        right: auto;
        left: auto;
        background: rgba(255, 255, 255, 0.5); }
        .tools .group.front {
          left: -50px;
          top: 40px; }
        .tools .group.back {
          left: auto;
          right: -50px;
          top: 90px; }
        .tools .group.create {
          top: 245px;
          left: -40px; }
        .tools .group.host {
          top: 350px;
          left: auto;
          right: -60px; }
        .tools .group.optim {
          top: 455px;
          left: -70px; }
        .tools .group.pm {
          top: 550px;
          left: auto;
          right: -60px; } }

.sprints, .support {
  text-align: center;
  padding-bottom: 100px; }
  .sprints h2, .support h2 {
    font: normal bold 28px/36px "Whitney A", "Whitney B", sans-serif;
    margin: 0;
    text-align: center;
    text-transform: uppercase; }
  .sprints p, .support p {
    font: normal normal 20px/28px "Whitney A", "Whitney B", sans-serif;
    margin: 0 auto;
    max-width: 660px; }
  @media screen and (max-width: 750px) {
    .sprints, .support {
      padding-bottom: 50px; }
      .sprints h2, .support h2 {
        font-size: 22px;
        line-height: 28px;
        padding-bottom: 10px; }
      .sprints p, .support p {
        font-size: 16px;
        line-height: 22px; } }

.pricing {
  background: #99898e url(/_img/mystical.jpg) no-repeat bottom center;
  background-size: cover;
  color: #FFF; }
  .pricing h2 {
    margin-left: 6.38298%;
    font-style: normal;
    font-size: 54px;
    line-height: 0.95;
    letter-spacing: -2px;
    margin-bottom: 20px; }
  .pricing .text {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 48.93617%;
    margin-left: 6.38298%;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    padding-bottom: 80px; }
    .pricing .text:last-child {
      margin-right: 0; }
    .pricing .text p {
      margin: 0;
      padding: 0;
      text-indent: 30px;
      line-height: 1.3; }
      .pricing .text p:first-child {
        text-indent: 0; }
      .pricing .text p span {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px; }
  @media screen and (max-width: 750px) {
    .pricing .text {
      margin-left: 0%;
      -webkit-columns: 1;
      -moz-columns: 1;
      columns: 1;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .pricing .text:last-child {
        margin-right: 0; }
    .pricing h2 {
      font-size: 42px;
      margin-left: 0%; }
      .pricing h2 br {
        display: none; } }

.services .image {
  float: left;
  display: block;
  margin-right: 2.12766%;
  width: 23.40426%;
  margin-left: 6.38298%;
  opacity: 0.5;
  margin-top: 50px; }
  .services .image:last-child {
    margin-right: 0; }

.services ul {
  float: left;
  display: block;
  margin-right: 2.12766%;
  width: 61.70213%;
  margin-left: 6.38298%;
  margin-left: 0;
  margin-top: 30px;
  padding-left: 90px;
  font: bold 24px/1 "Whitney A", "Whitney B", sans-serif;
  list-style: none; }
  .services ul:last-child {
    margin-right: 0; }
  .services ul li {
    float: left;
    width: 50%;
    margin: 8px 0;
    padding-top: 3px;
    background: url(/img/check.svg) no-repeat left center;
    padding-left: 50px;
    background-size: 30px 30px; }

.legend {
  padding: 40px 0 40px 0; }
  .legend a {
    position: relative;
    font: bold 15px/80px "Whitney A", "Whitney B", sans-serif;
    color: #FFF;
    padding: 0;
    text-shadow: none;
    width: 80px;
    height: 80px;
    display: inline-block;
    text-decoration: none;
    border-radius: 100%;
    margin: 5px; }
    .legend a.research, .legend a.research .tooltip {
      background: #B03C96; }
    .legend a.content, .legend a.content .tooltip {
      background: #E7802C; }
    .legend a.design, .legend a.design .tooltip {
      background: #F9C327;
      color: #000; }
    .legend a.code, .legend a.code .tooltip {
      background: #3763AE; }
    .legend a.testing, .legend a.testing .tooltip {
      background: #6F972A; }
    .legend a:hover {
      padding: 0; }
    .legend a.research:hover:before {
      border-left: 3px #B03C96 solid; }
    .legend a.content:hover:before {
      border-left: 3px #E7802C solid; }
    .legend a.design:hover:before {
      border-left: 3px #F9C327 solid; }
    .legend a.code:hover:before {
      border-left: 3px #3763AE solid; }
    .legend a.testing:hover:before {
      border-left: 3px #6F972A solid; }
    .legend a .tooltip {
      position: absolute;
      opacity: 0;
      bottom: 90px;
      left: 50%;
      width: 200px;
      text-align: center;
      font-size: 14px;
      font-weight: normal;
      padding: 15px;
      border-radius: 9px;
      margin-left: -100px;
      line-height: 1.2;
      transition: all 0.3s ease;
      pointer-events: none; }
    .legend a:hover .tooltip, .legend a:active .tooltip {
      opacity: 1;
      bottom: 100px; }
    .legend a:hover:before, .legend a:active:before {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -2px;
      top: -20px;
      height: 20px; }
  @media screen and (max-width: 750px) {
    .legend {
      position: relative;
      max-width: 300px;
      margin: 0 auto; }
      .legend a {
        position: static; }
        .legend a.research:hover:before, .legend a.content:hover:before, .legend a.design:hover:before, .legend a.code:hover:before, .legend a.testing:hover:before {
          border-left: 0;
          display: none; }
        .legend a .tooltip {
          margin-left: 0;
          width: 100%;
          left: 0;
          top: auto;
          bottom: 200px; }
        .legend a:hover .tooltip, .legend a:active .tooltip {
          bottom: 210px; } }

.donut path#seg_research {
  fill: #B03C96; }
  .donut path#seg_research:hover {
    fill: #9e3687; }
  .donut path#seg_research.off {
    fill: #73606f; }

.donut path#seg_content {
  fill: #E7802C; }
  .donut path#seg_content:hover {
    fill: #d07328; }
  .donut path#seg_content.off {
    fill: #a18773; }

.donut path#seg_design {
  fill: #F9C327; }
  .donut path#seg_design:hover {
    fill: #e0b023; }
  .donut path#seg_design.off {
    fill: #b5a885; }

.donut path#seg_code {
  fill: #3763AE; }
  .donut path#seg_code:hover {
    fill: #32599d; }
  .donut path#seg_code.off {
    fill: #656f80; }

.donut path#seg_testing {
  fill: #6F972A; }
  .donut path#seg_testing:hover {
    fill: #648826; }
  .donut path#seg_testing.off {
    fill: #6f7466; }

.donut circle {
  fill: #EFEAE9; }

.days > a {
  width: 20%;
  display: block;
  float: left;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .days > a svg {
    width: 90%;
    height: 90%; }
  .days > a span {
    display: block;
    z-index: 10;
    position: absolute;
    color: #000;
    font: bold 20px/20px "Whitney A", "Whitney B", sans-serif;
    top: 50%;
    left: 0;
    width: 100%;
    height: auto;
    margin-top: -10px;
    transition: all 0.3s ease; }
  .days > a div {
    height: 200px;
    z-index: 5;
    pointer-events: none; }
    .days > a div.what {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      font: 14px/1.2 "Whitney A", "Whitney B", sans-serif;
      color: #000;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .days > a div.what strong {
        opacity: 0;
        transform: scale(0.1);
        transition: all 0.3s ease;
        background: rgba(255, 255, 255, 0.9);
        font-weight: normal;
        padding: 20px;
        border-radius: 9px;
        z-index: 99; }
  .days > a:hover div.what strong {
    display: block;
    opacity: 1;
    transform: scale(1); }
  .days > a:hover span {
    opacity: 0; }
  @media screen and (max-width: 750px) {
    .days > a {
      width: 50%;
      height: 160px; }
      .days > a div {
        height: 160px; }
        .days > a div.what {
          height: 160px; }
          .days > a div.what strong {
            padding: 15px; } }

.support {
  padding-bottom: 20px; }
  .support article {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 87.23404%;
    margin-left: 6.38298%; }
    .support article:last-child {
      margin-right: 0; }
  .support .plans {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    border-top: 1px #777 solid; }
    .support .plans::after {
      clear: both;
      content: "";
      display: table; }
    .support .plans svg {
      height: 80px;
      margin-top: 40px; }
    .support .plans path {
      fill: #271120; }
  .support .supportplan {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 31.91487%;
    position: relative;
    padding-bottom: 80px; }
    .support .supportplan:last-child {
      margin-right: 0; }
    .support .supportplan:before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #999;
      background-image: -webkit-linear-gradient(#999, #EFEAE9);
      background-image: linear-gradient(#999, #EFEAE9); }
    .support .supportplan:first-child:before {
      display: none; }
    .support .supportplan h3 {
      color: #000;
      text-transform: none;
      font: 32px/1.2 "Mercury Display A", "Mercury Display B", serif;
      padding-bottom: 30px;
      margin: 20px 0 25px 0;
      position: relative; }
      .support .supportplan h3:before {
        content: '';
        left: 50%;
        position: absolute;
        width: 40px;
        bottom: 0;
        border-bottom: 2px #999 solid;
        margin-left: -20px; }
    .support .supportplan ul {
      list-style: none;
      padding: 0;
      font-size: 18px;
      line-height: 1.2; }
    .support .supportplan li {
      padding: 5px 0; }
  @media screen and (max-width: 750px) {
    .support .plans {
      margin-top: 40px; }
      .support .plans svg {
        height: 50px;
        margin-top: 20px; }
    .support .supportplan {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%;
      padding-bottom: 20px; }
      .support .supportplan:last-child {
        margin-right: 0; }
      .support .supportplan:before {
        display: none; }
      .support .supportplan ul {
        font-size: 14px;
        line-height: 1; }
      .support .supportplan h3 {
        margin-top: 10px;
        padding-bottom: 0; }
        .support .supportplan h3:before {
          display: none; } }

.above {
  position: relative;
  z-index: 9999; }

html.purple, body.thanks {
  background: #271120; }

body.thanks .contact {
  text-align: center;
  padding: 100px 50px; }
  body.thanks .contact p {
    font-size: 24px;
    line-height: 1.2; }

.contact {
  background: #271120;
  color: #FFF;
  z-index: 1000;
  position: relative;
  padding: 0 0 100px 0; }
  .contact article, .contact form {
    max-width: 600px;
    padding: 0 20px;
    margin: 0 auto;
    font-size: 25px;
    line-height: 1.3;
    text-align: center; }
  .contact.full .btn {
    margin: 0 5px; }
  .contact form {
    max-width: 500px; }
  .contact h3 {
    color: #FFF;
    text-transform: none;
    text-align: center;
    margin: 0;
    padding: 60px 0 0 0; }
  @media screen and (max-width: 750px) {
    .contact article {
      font-size: 18px; }
    .contact p {
      hyphens: none;
      -webkit-hyphens: none; } }

.proposals {
  background: #DDD; }
  .proposals h4 {
    text-align: center;
    font: bold 11px/1.2 "Whitney A", "Whitney B", sans-serif;
    text-transform: uppercase; }
  .proposals form {
    border: 1px #CCC solid;
    background: #FFF;
    width: 320px;
    padding: 20px;
    margin: 0 auto;
    box-shadow: #666 0 10px 40px; }
    .proposals form input[type="text"],
    .proposals form input[type="password"] {
      font: bold 18px/1.2 "Whitney A", "Whitney B", sans-serif;
      background: #EEE;
      padding: 6px;
      width: 100%;
      outline: none;
      border: 1px #999 solid; }
    .proposals form label {
      font: bold 11px/1.2 "Whitney A", "Whitney B", sans-serif;
      text-transform: uppercase;
      margin: 0;
      padding: 0; }

.colorhead {
  position: relative;
  margin-top: -140px;
  background: #EFEAE9 url(/img/blog.svg) no-repeat center bottom;
  background-size: auto 120%;
  padding: 240px 0 250px; }
  .colorhead h4 {
    text-align: center;
    margin: 0 0 20px;
    padding: 0;
    color: #FFF;
    text-transform: uppercase;
    opacity: 0.3; }
  .colorhead h1 {
    color: #FFF;
    text-align: center;
    padding: 0 200px;
    line-height: 1; }
  @media screen and (max-width: 960px) {
    .colorhead h1 {
      padding: 0 40px;
      font-size: 72px; }
    .colorhead h4 {
      font-size: 18px;
      margin: 0 0 10px; } }
  @media screen and (max-width: 750px) {
    .colorhead {
      margin-top: -180px; }
      .colorhead h1 {
        font-size: 48px;
        padding: 0 20px; }
      .colorhead h4 {
        font-size: 16px; } }

.post aside {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 36%;
  background-color: #EFEAE9;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right; }
  @media screen and (max-width: 750px) {
    .post aside {
      position: relative;
      width: auto;
      margin: 0;
      height: 350px;
      background-size: auto 100%;
      background-position: center center; } }

.post article {
  width: 55%;
  margin-left: 38%;
  padding: 60px 0; }
  .post article h1 {
    font-size: 84px;
    line-height: 80px; }
  .post article h2 {
    font: bold 32px/1.3 "Whitney A", "Whitney B", sans-serif;
    padding: 30px 0 0 0;
    margin: 0; }
  .post article h4 {
    color: #999;
    margin: 30px 0;
    font-size: 13px;
    text-align: center; }
  .post article article {
    width: 100%;
    margin-left: 0;
    padding: 0; }
  .post article a {
    color: #3A65AC; }
    .post article a:hover {
      color: #2e518a; }
  @media screen and (max-width: 750px) {
    .post article {
      width: 100%;
      margin-left: 0;
      padding: 0; }
      .post article h1 {
        font-size: 54px;
        line-height: 1;
        margin-bottom: 40px; }
      .post article h2 {
        font-size: 25px; } }

.post .intro {
  font-size: 32px;
  line-height: 1.3;
  padding-bottom: 20px;
  border-bottom: 1px #cec5c6 solid; }
  @media screen and (max-width: 750px) {
    .post .intro {
      font-size: 24px; } }

.post .text {
  position: relative; }
  .post .text.bordertop {
    border-top: 1px #cec5c6 solid;
    margin-top: 40px;
    padding-top: 10px; }

.post.newsletter .intro {
  margin-top: -200px;
  position: relative;
  z-index: 100;
  font-size: 26px;
  line-height: 1.4; }
  .post.newsletter .intro::first-letter {
    color: #7593c5;
    float: left;
    margin: 0 10px 0 -20px;
    font: bold 180px/130px "Mercury Display A", "Mercury Display B", serif; }
    @media screen and (max-width: 750px) {
      .post.newsletter .intro::first-letter {
        font: bold 150px/115px "Mercury Display A", "Mercury Display B", serif;
        margin: 0 10px 0 0; } }

.post.newsletter article {
  margin-left: 20%;
  width: 60%; }
  .post.newsletter article article {
    padding-left: 70px;
    margin-left: 0;
    width: 100%; }
    .post.newsletter article article .number {
      background: #C4549F;
      color: #FFF;
      position: absolute;
      top: 34px;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      text-align: center;
      font: bold 26px/40px "Whitney A", "Whitney B", sans-serif; }

@media screen and (max-width: 960px) {
  .post.newsletter {
    padding: 0 40px; }
    .post.newsletter article {
      margin-left: 5%;
      width: 90%; }
      .post.newsletter article article {
        margin-left: 0;
        width: 100%; } }

@media screen and (max-width: 750px) {
  .post.newsletter {
    padding: 0 30px; }
    .post.newsletter article {
      margin-left: 0;
      width: 100%; }
      .post.newsletter article article {
        padding-left: 50px; }
        .post.newsletter article article .number {
          left: -10px; }
        .post.newsletter article article p {
          line-height: 1.4; }
    .post.newsletter .intro {
      margin-top: -210px;
      font-size: 22px; } }

.plate {
  padding: 0 60px 40px;
  width: 100%;
  animation: redesign 7s ease infinite;
  margin: 0; }
  .plate.redesign {
    background: linear-gradient(180deg, #6F972A, #C4549F);
    background-size: 600% 600%; }
  .plate p {
    font-size: 36px;
    line-height: 1.1;
    opacity: 0.6;
    max-width: 700px;
    letter-spacing: -1px; }
  .plate h1 {
    margin: 0;
    font: bold 108px/1.3 "Whitney A", "Whitney B", sans-serif; }
  .plate h4 {
    margin: 0 0 20px 0; }
    .plate h4 span {
      font-weight: 500;
      font-size: 15px;
      margin-left: 10px;
      text-transform: uppercase; }

@keyframes redesign {
  0% {
    background-position: 0% 44%; }
  50% {
    background-position: 100% 57%; }
  100% {
    background-position: 0% 44%; } }

.pagination {
  text-align: center;
  padding: 60px 0;
  border-top: 1px #cec5c6 solid; }
  .pagination a {
    display: inline-block;
    border: 2px #6F972A solid;
    font: bold 18px/1.2 "Whitney A", "Whitney B", sans-serif;
    border-radius: 12px;
    padding: 10px 20px;
    text-decoration: none;
    margin: 0 20px; }
    .pagination a:hover {
      background: #6F972A;
      color: #FFF; }

.blog.landing article {
  padding-left: 450px;
  padding-right: 10%;
  position: relative;
  z-index: 100; }
  .blog.landing article ul {
    list-style: none;
    margin: 0; }
  .blog.landing article p {
    margin: 0 0 15px 0;
    font-size: 24px; }
    .blog.landing article p a.btn {
      text-shadow: none !important;
      display: inline-block;
      margin-top: 20px; }
  .blog.landing article h4 {
    font-weight: normal;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    font-size: 14px; }
    .blog.landing article h4 span {
      padding-left: 15px; }
  .blog.landing article h3 a {
    background-image: linear-gradient(rgba(111, 151, 42, 0.25) 0%, #6F972A 100%);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 90%;
    text-decoration: none;
    text-shadow: 3px 0 #EFEAE9, 2px 0 #EFEAE9, 1px 0 #EFEAE9, -1px 0 #EFEAE9, -2px 0 #EFEAE9, -3px 0 #EFEAE9; }
    .blog.landing article h3 a:hover {
      background-image: linear-gradient(rgba(239, 234, 233, 0.25) 0%, #EFEAE9 100%); }
  .blog.landing article li {
    padding: 30px 0 30px 0; }
  .blog.landing article h5 {
    margin: 0; }
  .blog.landing article h3 {
    font: bold 58px/1 "Mercury Display A", "Mercury Display B", serif;
    margin: 0;
    text-transform: none;
    margin-bottom: 30px; }

.blog.landing aside {
  width: 40%;
  position: fixed;
  top: 50%;
  margin-top: -320px; }

.blog.landing.blogentry nav a {
  color: #FFF;
  text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px; }

.blog.landing img {
  width: 600px;
  max-width: 600px;
  height: auto;
  margin: 0 0 0 -200px;
  display: block;
  z-index: 99;
  position: relative; }

.blog.landing .butterfly {
  left: 150px;
  position: absolute;
  top: 150px;
  margin: 0 0 0 74px; }

@media screen and (max-width: 1100px) {
  .blog.landing article {
    padding-left: 400px;
    padding-right: 0; }
  .blog.landing aside {
    width: 35%; } }

@media screen and (max-width: 960px) {
  .blog.landing img {
    width: 500px;
    margin: 0 0 0 -150px; }
  .blog.landing article {
    padding-left: 350px; }
  .blog.landing aside {
    margin-top: -250px; }
  .blog.landing .butterfly {
    top: 103px;
    margin: 0 0 0 40px; } }

@media screen and (max-width: 750px) {
  .blog.landing aside {
    position: relative;
    width: 100%;
    margin-top: -100px; }
  .blog.landing article {
    padding: 0; }
    .blog.landing article h3 {
      text-align: left;
      font-size: 40px; }
    .blog.landing article ul {
      padding: 0; }
    .blog.landing article p {
      font-size: 20px; }
  .blog.landing img {
    width: 400px;
    margin: 0 0 0 -80px;
    padding: 20px 0; }
  .blog.landing .butterfly {
    top: 95px;
    width: 90px;
    margin: 0 0 0 40px; } }

.blogabout {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  background: #E5DBDC;
  display: flex;
  align-items: center;
  position: relative; }
  .blogabout::after {
    clear: both;
    content: "";
    display: table; }
  .blogabout img {
    flex: 0 0 550px;
    width: 550px;
    position: absolute;
    margin: 80px 40px 40px -100px;
    display: block; }
  .blogabout .butterfly {
    position: absolute;
    left: 340px;
    top: 205px; }
  .blogabout article.blogpanel {
    margin: 40px 0 40px 450px;
    width: 100%;
    position: relative; }
    .blogabout article.blogpanel .thanks {
      position: absolute;
      bottom: 20px;
      padding: 0 120px;
      font: normal 18px/1.4 "Mercury Display A", "Mercury Display B", serif;
      opacity: 0;
      display: block;
      pointer-events: none;
      transition: all 0.5s ease; }
      .blogabout article.blogpanel .thanks p {
        margin-bottom: 30px; }
      .blogabout article.blogpanel .thanks.on {
        display: block;
        bottom: 30px;
        opacity: 1;
        pointer-events: auto; }
    .blogabout article.blogpanel .loader {
      pointer-events: none;
      display: block;
      opacity: 0; }
      .blogabout article.blogpanel .loader .ring {
        border-color: #9d437f; }
    .blogabout article.blogpanel form {
      max-width: 580px;
      margin: 0 auto; }
      .blogabout article.blogpanel form p {
        font-size: 17px;
        text-align: center;
        padding: 10px 20px;
        float: left;
        width: 100%; }
    .blogabout article.blogpanel input[type="email"] {
      float: left;
      width: 63%; }
    .blogabout article.blogpanel input[type="submit"] {
      float: left;
      width: 35%;
      margin-top: 0;
      margin-left: 2%; }
    @media screen and (max-width: 750px) {
      .blogabout article.blogpanel input[type="email"],
      .blogabout article.blogpanel input[type="submit"] {
        font-size: 17px;
        padding: 6px 10px; } }
    .blogabout article.blogpanel h2 {
      font: bold 40px/1.2 "Whitney A", "Whitney B", sans-serif;
      color: #7F6C72;
      text-align: center;
      text-transform: uppercase;
      padding: 60px 0 0 0;
      margin: 0; }
    .blogabout article.blogpanel ul {
      max-width: 1900px;
      margin-left: auto;
      margin-right: auto;
      margin: 0;
      padding: 0 0 60px 0;
      list-style: none;
      font: bold 42px/1 "Mercury Display A", "Mercury Display B", serif; }
      .blogabout article.blogpanel ul::after {
        clear: both;
        content: "";
        display: table; }
    .blogabout article.blogpanel li {
      width: 50%;
      float: left;
      padding: 0;
      height: 220px;
      border-bottom: 1px #999 solid;
      border-right: 1px #999 solid;
      list-style: none;
      text-align: center; }
      .blogabout article.blogpanel li:nth-child(3), .blogabout article.blogpanel li:nth-child(4) {
        border-bottom: 0; }
      .blogabout article.blogpanel li:nth-child(2), .blogabout article.blogpanel li:nth-child(4) {
        border-right: 0; }
      .blogabout article.blogpanel li a {
        text-decoration: none;
        padding: 25px 40px 60px;
        color: #000;
        position: relative;
        display: block;
        height: 100%;
        align-items: center; }
        .blogabout article.blogpanel li a strong {
          width: 100%;
          color: #271120; }
        .blogabout article.blogpanel li a span, .blogabout article.blogpanel li a em {
          font: normal 17px/1.2 "Mercury Display A", "Mercury Display B", serif;
          padding: 20px 20px 14px;
          text-transform: uppercase; }
          .blogabout article.blogpanel li a span.meta, .blogabout article.blogpanel li a em.meta {
            display: block;
            font: normal 14px/1.2 "Whitney A", "Whitney B", sans-serif;
            text-align: center; }
        .blogabout article.blogpanel li a:hover {
          background: #f2eeed; }
        .blogabout article.blogpanel li a em {
          font: normal normal 14px/1.2 "Whitney A", "Whitney B", sans-serif; }
  @media screen and (max-width: 960px) {
    .blogabout img {
      flex: 0 0 450px;
      width: 450px;
      margin-left: -200px; }
    .blogabout article.blogpanel {
      margin: 40px 0 40px 250px; }
      .blogabout article.blogpanel ul {
        font-size: 32px;
        padding-bottom: 20px; }
      .blogabout article.blogpanel li {
        width: 100%;
        height: auto;
        border: 0; }
        .blogabout article.blogpanel li a {
          padding: 25px 10px; }
          .blogabout article.blogpanel li a span {
            padding: 0 0 10px; } }
  @media screen and (max-width: 750px) {
    .blogabout {
      display: block;
      padding-top: 30px; }
      .blogabout img {
        flex: 0 0 240px;
        width: 240px;
        margin: 0 auto;
        position: relative; }
      .blogabout .butterfly {
        left: 50%;
        margin-left: 30px;
        top: 45px;
        width: 60px; }
      .blogabout article {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%; }
        .blogabout article:last-child {
          margin-right: 0; }
        .blogabout article.blogpanel {
          margin: 40px 0 40px 0; } }

.homeprocess {
  background: #7F6C72;
  color: #FFF;
  display: flex;
  align-items: center;
  padding: 60px 30px 60px 60px;
  justify-content: center; }
  .homeprocess h2 {
    font-style: normal;
    letter-spacing: -3px;
    font-size: 68px;
    padding: 0 0 10px; }
  .homeprocess p {
    font: 22px/1.4 "Whitney A", "Whitney B", sans-serif;
    margin: 30px 0 40px 0;
    max-width: 284px; }
  .homeprocess circle {
    fill: #7F6C72; }
  .homeprocess .text {
    flex: 0 0 380px;
    width: 380px; }
  .homeprocess .btn:hover {
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 20px; }
  .homeprocess .details {
    position: absolute;
    pointer-events: none;
    top: 78px;
    left: 103px;
    padding: 0;
    text-align: center;
    border-radius: 100%; }
  .homeprocess .donut {
    flex-grow: 1;
    max-width: 500px;
    position: relative;
    height: 100%; }
    .homeprocess .donut svg {
      width: 100%; }
    .homeprocess .donut path {
      transition: all 0.2s ease; }
    .homeprocess .donut text {
      pointer-events: none; }
    .homeprocess .donut path:hover {
      cursor: pointer; }
    .homeprocess .donut .days > div {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center; }
      .homeprocess .donut .days > div.details {
        width: 299px;
        height: 299px;
        display: block; }
      .homeprocess .donut .days > div.off {
        opacity: 1; }
      .homeprocess .donut .days > div > div {
        height: 500px; }
      .homeprocess .donut .days > div span {
        opacity: 0;
        display: flex;
        z-index: 10;
        color: #000;
        font: normal 20px/1.3 "Whitney A", "Whitney B", sans-serif;
        width: 100%;
        height: auto;
        position: absolute;
        transition: all 0.3s ease;
        text-align: center;
        color: #FFF;
        align-items: center;
        justify-content: center;
        width: 299px;
        height: 299px;
        border-radius: 100%;
        padding: 40px 40px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 30px;
        transform: scale(0.8); }
        .homeprocess .donut .days > div span.grow {
          transform: scale(1);
          opacity: 1; }
        .homeprocess .donut .days > div span em {
          display: block;
          font-style: normal; }
        .homeprocess .donut .days > div span.research {
          background: #B03C96; }
        .homeprocess .donut .days > div span.content {
          background: #E7802C; }
        .homeprocess .donut .days > div span.design {
          background: #F9C327;
          color: #000; }
        .homeprocess .donut .days > div span.code {
          background: #3763AE; }
        .homeprocess .donut .days > div span.testing {
          background: #6F972A; }
  @media screen and (max-width: 750px) {
    .homeprocess {
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px 30px; }
      .homeprocess h2 {
        text-align: center;
        font-size: 48px; }
      .homeprocess p {
        margin: 0 0 30px 0;
        max-width: 100%;
        text-align: center;
        hyphens: none; }
      .homeprocess text {
        display: none; }
      .homeprocess .text {
        width: 100%;
        padding: 20px 0 30px;
        flex: 0 0 auto;
        width: auto; }
      .homeprocess .donut {
        height: auto;
        margin: 0 0 20px 0; }
        .homeprocess .donut svg {
          height: 160px; }
        .homeprocess .donut .days > div.details {
          display: none; } }

.homework {
  background: #E5DBDC; }
  .homework h2 {
    text-align: center;
    font-style: normal;
    letter-spacing: -3px;
    font-size: 68px;
    padding: 65px 0 15px; }
  .homework p {
    text-align: center;
    padding: 30px 0; }
    .homework p .btn {
      padding: 10px 30px; }
  .homework .cases li:nth-child(3n) {
    margin-right: 0; }
    .homework .cases li:nth-child(3n) a {
      border-right: 0; }
  .homework .cases li:nth-child(3n+1) {
    clear: left; }
  .homework .quote {
    margin-top: 20px;
    margin-bottom: 40px;
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 68.08511%;
    margin-left: 15.95745%; }
    .homework .quote:last-child {
      margin-right: 0; }
    .homework .quote p {
      font: bold 30px/1.2 "Mercury Display A", "Mercury Display B", serif;
      padding: 0; }
    .homework .quote span {
      font: bold 18px/1 "Whitney A", "Whitney B", sans-serif;
      text-transform: uppercase;
      display: block;
      text-align: center;
      padding: 20px 0 60px 0;
      color: #7F6C72; }
  @media screen and (max-width: 750px) {
    .homework h2 {
      font-size: 48px; }
    .homework .quote {
      margin-left: 6.38298%;
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 87.23404%; }
      .homework .quote:last-child {
        margin-right: 0; }
      .homework .quote p {
        hyphens: none;
        font-size: 24px; } }

.cases {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 0 0;
  margin: 0;
  list-style: none; }
  .cases::after {
    clear: both;
    content: "";
    display: table; }
  .cases li {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 31.91489%; }
    .cases li:last-child {
      margin-right: 0; }
    @media screen and (max-width: 960px) {
      .cases li {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 48.93617%; }
        .cases li:last-child {
          margin-right: 0; }
        .cases li:nth-child(3n) {
          margin-right: 2.12766%; }
        .cases li:nth-child(3n+1) {
          clear: none; }
        .cases li:nth-child(2n) {
          margin-right: 0; }
          .cases li:nth-child(2n) a {
            border-right: 0; }
        .cases li:nth-child(2n+1) {
          clear: left; } }
  .cases svg {
    position: absolute;
    height: auto;
    width: 60px;
    top: 30px;
    left: 50%;
    margin-left: -30px; }
  .cases path {
    fill: #000; }
  .cases a {
    padding: 100px 0 10px 0;
    display: block;
    height: 190px;
    margin: 24px 0 0 0;
    position: relative;
    background-position: center 25px;
    background-repeat: no-repeat;
    background-size: 50px auto;
    text-align: center;
    border-right: 1px #999 solid;
    border-bottom: 1px #999 solid;
    border-left: 1px #CCC solid;
    border-top: 1px #CCC solid;
    background-color: rgba(255, 255, 255, 0.2);
    color: #000;
    text-decoration: none; }
    .cases a:hover {
      margin: 27px -2px -3px 2px;
      background-color: rgba(255, 255, 255, 0.5); }
    .cases a strong {
      display: block;
      font-size: 32px;
      font-weight: normal;
      font-family: "Mercury Display SC A", "Mercury Display SC B", serif;
      letter-spacing: -0; }
    .cases a span {
      display: block;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0;
      margin-left: 2px; }
  .cases .small-screen-network svg {
    width: 80px;
    margin-left: -40px; }
  .cases .cedar-grove svg {
    width: 30px;
    height: auto;
    margin-left: -15px; }
  .cases .tenants-union svg {
    width: 60px;
    margin-left: -30px; }
  .cases .shopseptember svg {
    width: 90px;
    margin-left: -45px; }
  .cases .no-barriers svg {
    width: 70px;
    margin-left: -35px; }
  .cases .windermere svg {
    width: 70px;
    margin-left: -35px; }
  @media screen and (max-width: 750px) {
    .cases li {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .cases li:last-child {
        margin-right: 0; }
    .cases.second3 {
      display: none; } }

.featured {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto; }
  .featured::after {
    clear: both;
    content: "";
    display: table; }
  .featured .cases {
    float: left;
    display: block;
    margin-right: 2.12766%;
    width: 31.91489%; }
    .featured .cases:last-child {
      margin-right: 0; }
    .featured .cases li {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .featured .cases li:last-child {
        margin-right: 0; }
    .featured .cases.featured {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 65.9574%; }
      .featured .cases.featured:last-child {
        margin-right: 0; }
      .featured .cases.featured.right {
        float: right;
        margin-left: 2.12766%;
        margin-right: 0; }
      .featured .cases.featured li a {
        height: 404px;
        padding-top: 180px;
        color: #FFF;
        text-shadow: #000 1px 1px 0;
        line-height: 48px; }
        .featured .cases.featured li a strong {
          font-size: 50px; }
        .featured .cases.featured li a span {
          font-size: 20px; }
      .featured .cases.featured svg {
        top: 100px; }
      .featured .cases.featured path {
        fill: #FFF;
        stroke: #000; }
  @media screen and (max-width: 750px) {
    .featured .cases {
      float: left;
      display: block;
      margin-right: 2.12766%;
      width: 100%; }
      .featured .cases:last-child {
        margin-right: 0; }
      .featured .cases li, .featured .cases li.featured, .featured .cases.featured {
        float: left;
        display: block;
        margin-right: 2.12766%;
        width: 100%; }
        .featured .cases li:last-child, .featured .cases li.featured:last-child, .featured .cases.featured:last-child {
          margin-right: 0; } }

form {
  overflow: hidden;
  position: relative;
  transition: all 0.7s ease; }

div.loader, div.thanks {
  position: absolute;
  display: none;
  left: 50%;
  top: 500px;
  margin-left: -30px; }

div.thanks {
  left: 0;
  margin-left: 0;
  text-align: center;
  width: 100%;
  padding: 0 100px;
  font: 700 36px/1 "Mercury Display A", "Mercury Display B", serif; }

@media screen and (max-width: 750px) {
  .loader, .thanks {
    top: 350px; } }

.ring {
  border: 6px solid #FFF;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  -webkit-animation: pulsate 1.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0; }

input[type="submit"] {
  cursor: pointer;
  background: #6F972A;
  color: #FFF;
  padding: 12px 30px;
  font: bold 24px/30px "Whitney A", "Whitney B", sans-serif;
  margin-top: 15px;
  border-radius: 7px;
  border: 0; }

textarea {
  width: 100%;
  border: 0;
  height: 110px;
  border-radius: 7px;
  font: normal 24px/30px "Whitney A", "Whitney B", sans-serif;
  padding: 12px 15px;
  outline: none; }

input[type="email"], input[type="text"] {
  display: block;
  font: normal 24px/30px "Whitney A", "Whitney B", sans-serif;
  padding: 12px 15px;
  border: 0;
  margin: 0 auto;
  border-radius: 9px;
  width: 100%;
  outline: none; }
  input[type="email"].error, input[type="text"].error {
    background: #E5DBDC; }

input.important {
  display: none; }

label {
  display: block;
  text-align: left;
  font: bold 15px/24px "Whitney A", "Whitney B", sans-serif;
  text-transform: uppercase; }
  label.error {
    display: none; }

.butterfly {
  width: 126px;
  height: auto;
  transform: rotate(5deg) translate(30px, 60px) scale(0.8, 0.8);
  transform-origin: center center;
  transition: all 3s ease-in; }
  .butterfly.on {
    transform: rotate(-5deg) translate(-830px, -760px) scale(1.2, 1.2); }

.rightwing, .leftwing {
  transform-origin: center left;
  animation-name: leftwingani;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; }
  .rightwing.slow, .leftwing.slow {
    transform-origin: center left;
    animation-name: leftwingani;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; }
    .rightwing.slow.leftwing, .leftwing.slow.leftwing {
      transform-origin: center right; }
  .rightwing.slower, .leftwing.slower {
    transform-origin: center left;
    animation-name: leftwingani;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; }
    .rightwing.slower.leftwing, .leftwing.slower.leftwing {
      transform-origin: center right; }
  .rightwing.stop, .leftwing.stop {
    animation: none; }

.leftwing {
  transform-origin: center right; }

@keyframes leftwingani {
  from {
    transform: rotate3d(0, 1, 0, 0deg); }
  20% {
    transform: rotate3d(0, 1, 0, 0deg); }
  70% {
    transform: rotate3d(0, 1, 0, 80deg); }
  90% {
    transform: rotate3d(0, 1, 0, 0deg); }
  to {
    transform: rotate3d(0, 1, 0, 0deg); } }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    -webkit-transform: scale(1.5, 1.5);
    opacity: 0.0; } }

@keyframes heartbeat {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.18); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.15); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@keyframes flutter {
  0% {
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    opacity: 0.0; } }

@keyframes unflutter {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes shake {
  2% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  4% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  6% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  8% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  12% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  16% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  18% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  20% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  22% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  24% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  26% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  28% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  30% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  32% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  34% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  38% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  40% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  42% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  44% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  46% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  50% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  52% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  54% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  56% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  58% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  60% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  62% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  64% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  66% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  68% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  70% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  72% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  74% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  76% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  80% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  84% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  86% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  90% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  92% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  94% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  96% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  98% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } }
