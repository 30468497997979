$sentinel:'Sentinel A', 'Sentinel B', serif;
$whitney:'Whitney A', 'Whitney B', sans-serif;
$gothamcond:'Gotham Cond A', 'Gotham Cond B', sans-serif;
$chronicle:'Chronicle SSm A', 'Chronicle SSm B', serif;
$chronicle-sc:'Chronicle SSm Small Caps A', 'Chronicle SSm Small Caps B', serif;
$mercury-sc: "Mercury Display SC A", "Mercury Display SC B", serif;
$mercury:"Mercury SSm A", "Mercury SSm B";
$mercurydisplay:"Mercury Display A", "Mercury Display B", serif;

$green: #6F972A;
$pink: #EFEAE9;
$pinker: #E5DBDC;
$pinkle: #7F6C72;
$magenta: #C4549F;
$grey: #666;

$colorresearch: #B03C96;
$colorcontent: #E7802C;
$colordesign: #F9C327;
$colorcode: #3763AE;
$colortesting: #6F972A;

$gold: #F7C13A;
$orange: #E47F37;
$blue: #3A65AC;
$anotherpink: #AF3F94;


// breakpoints
$laptop:max-width 1100px;   // for more than 1024px width
$tablet: max-width 960px;        // tablet
$mobile: max-width 750px;         // mobile
$minispecial: max-width 685px;
$mini: max-width 360px;
$mobile-less:639px;
$mobile-hi-res: 767px (min-resolution 1.5dppx);  // mobile retina
$retina:(min-resolution 1.5dppx);

// grid
$column: 40px;
$gutter: 20px;
$grid-columns: 16;
$max-width: 1900px;

// ------------------------------------------------------------
// Omega Reset
// ------------------------------------------------------------

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter($grid-columns, $gutter); }
  &:nth-child(#{$nth}+1) { clear: none }
}

/* Responsive Mixins
   see: http://jakearchibald.github.com/sass-ie
------------------------------------------------ */

$fix-mqs: false !default;

@mixin respond-min($width : $mobile) {
  @if $fix-mqs {
    @if $fix-mqs >= $width {
      @content;
    }
  } @else {
    @media screen and (min-width: $width) {
      @content;
    }
  }
}

$old-ie: false !default;

@mixin old-ie {
  @if $old-ie {
    @content;
  }
}

.no-mobile {
  display: none;
  
  @include respond-min {
    display: block;
    &.inline { display: inline; }
  }
}
.no-mobile-inline {
  display: none;
  
  @include respond-min {
    display: inline-block;
  }
}

.mobile-only {
  display: block;
  
  @include respond-min {
    display: none;
  }
}
.tablet {
  display: block;
  
  @include respond-min($tablet) {
    display: none;
  }
}